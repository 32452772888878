<template>
  <div>
    <b-form
        style="color: #cceeff; width: 100%"
        inline>
      <b-form-group
          label="Пошук"
          label-for="searchUser"
          label-cols="3"
          content-cols="2"
      >
        <b-form-input id="searchUser" type="search" v-model="search"></b-form-input>
      </b-form-group>
      <b-form-checkbox style="margin-left: 35px" v-model="onlyEnglish">Англійські</b-form-checkbox>
      <b-form-checkbox style="margin-left: 35px" v-model="onlyHumans">Без ботів</b-form-checkbox>
      <b-select v-model="activeFilters" size="sm" style="margin-left: 35px;">
        <b-select-option value="all">Всі</b-select-option>
        <b-select-option value="active">Активні</b-select-option>
        <b-select-option value="disable">Неактивні</b-select-option>
      </b-select>
      <b-button
          class="ml-auto"
          size="sm"
          variant="outline-primary"
          @click="generateCsv">
        Експорт в .csv
      </b-button>

    </b-form>
    <div class="totals">
      <table>
        <thead>
          <th>Всього</th>
          <th>Активних</th>
          <th>Люди</th>
          <th>Боти</th>
          <th>Жінки</th>
          <th>Чоловіки</th>
        </thead>
        <tbody>
          <tr>
            <td>{{totals.total}}</td>
            <td>{{totals.active}}</td>
            <td>{{totals.human}}</td>
            <td>{{totals.bot}}</td>
            <td>{{totals.female}}</td>
            <td>{{totals.male}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="tableContainer">
      <br>
      <table class="table table-dark table-striped">
        <thead>
        <tr>
          <th>Логін</th>
          <th>Повне ім'я</th>
          <th>Стать</th>
          <th>Запізнень</th>
          <th>Відгуків</th>
          <th>Статус</th>
          <th>Дії</th>
        </tr>
        </thead>
        <Loader v-if="loading"></Loader>
        <tbody v-else>
        <tr v-for="user in users" :key="user.id">
          <td style="cursor: pointer;" @click="writeToBuffer(user.login)">{{user.login}}</td>
          <td :title="user.bot ? (user.phone + ' | ' + user.password) : ''">
            <i
                v-if="user.bot"
                class="fas fa-robot botAccount"
                style="color: #00ff05; margin-right: 10px"/>
            {{user.name}}
            <i
                v-bind:class="{'fa-solid fa-dollar-sign dollar' : user.price === 'expensive', 'fa-solid fa-coins coin' : user.price === 'cheap'}"/>
          </td>
          <td>
            <i
                class="genderIcon fa"
                v-bind:class="{'fa-mars maleIcon' : user.gender === 'male', 'fa-venus femaleIcon' : user.gender === 'female'}"
            >
            </i>
          </td>
          <td style="text-align: center">
            {{user.notify2HoursCount === 0 ? "-" : user.notify2HoursCount}}/<b>{{user.notify3HoursCount === 0 ? "-" : user.notify3HoursCount}}</b>
          </td>
          <td style="text-align: center">
            {{user.feedbackCount === 0 ? "-" : user.feedbackCount}}
          </td>
          <td>
            <input
                class="activeInput"
                v-if="user.chatId"
                type="checkbox"
                :checked="user.active"
                @click="changeStatus(user)">
            <template v-if="!user.chatId">Не підтверджений</template>
            <template v-else-if="user.active">Активний</template>
            <template v-else>Неактивний</template>
          </td>
          <td>
            <!--template v-if="user.chatId">
              <i class="fab fa-telegram telegramButton" @click="sendTelegramTest(user.id)"></i>
            </template -->
            <button class="deleteButton" @click="deleteUser(user)">
              <i class="fa fa-remove"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import Loader from "@/components/Loader";


export default {
  name: "UsersTable",
  components: {Loader},
  props: ["msg"],
  data() {
    return {
      users: [],
      loadedUsers: [],
      loading: false,
      search: '',
      onlyHumans: false,
      onlyEnglish: false,
      activeFilters: 'all'
    }
  },
  mounted() {
    this.reloadUsers();
  },
  watch: {
    search: function () {
      this.filterUsers();
    },
    onlyHumans: function () {
      this.filterUsers();
    },
    activeFilters: function () {
      this.filterUsers();
    },
    onlyEnglish: function () {
      this.filterUsers();
    },
    msg() {
      this.search = '';
      this.onlyHumans = false;
      this.activeFilters = 'all';
      this.onlyEnglish = false;
      this.reloadUsers();
    }
  },
  methods: {
    filterUsers() {
      this.users = this.loadedUsers;
      if (this.onlyHumans) {
        this.users = this.users.filter(user => !user.bot);
      }
      if (this.activeFilters === 'active') {
        this.users = this.users.filter(user => user.active);
      }
      if (this.activeFilters === 'disable') {
        this.users = this.users.filter(user => !user.active);
      }
      if (this.onlyEnglish) {
        this.users = this.users.filter(user => user.english)
      }
      if (this.search.length > 1) {
        this.users = this.users.filter(user => {
          return (user.login.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
              user.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
        });
      }
    },
    async sendTelegramTest(userId) {
      await api.sendTelegramTest(userId).then(
          response => {
            console.log(response.data);
          }
      );
    },
    async reloadUsers() {
      this.loading = true;
      await api.getUsers().then(
          response => {
            this.loadedUsers = response.data;
            this.users = this.loadedUsers;
          }
      );
      this.loading = false;
    },
    deleteUser: async function (user) {
      this.$confirm(
          {
            message: 'Ви дійсно хочете видалити користувача ' + user.login + '?',
            button: {
              yes: 'Так',
              no: 'Ні'
            },
            callback: confirm => {
              if (confirm) {
                api.deleteUser(user.id)
                    .then(
                        response => {
                          if (response.status === 200)
                            this.reloadUsers();
                        }
                    );
              }
            }
          });
    },
    changeStatus: function (user) {
      api.changeUserStatus(user.id)
        .then(
            response => {
              if (response.status === 200)
                user.active = !user.active;
            }
        );
    },
    generateCsv: function () {
      const delimiter = ";";
      const header = ['id', 'Ім\'я', "Логін", "Пароль", "Телефон", "Стать", "К-ть відгуків", "Запізнень"];
      const csv = [
        header.join(delimiter),
        ...this.loadedUsers.map( row =>
          row.id + delimiter +
          row.name + delimiter +
          row.login + delimiter +
          row.password + delimiter +
          this.formatPhone(row.phone) + delimiter +
          row.gender + delimiter +
          row.feedbackCount + delimiter +
          row.notify2HoursCount + "/" + row.notify3HoursCount
        )
      ].join('\r\n');
      this.download("users.csv", csv);
    },
    download: function(filename, text) {
      const element = document.createElement('a');

      element.setAttribute('href', 'data:text/csv;charset=windows-1251,' + this.encodeURL(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    formatPhone: function (phone) {
      return "(" + phone.substring(2, 5) + ")" + phone.substring(5, 8) + "-" + phone.substring(8, 10) + "-" + phone.substring(10);
    },
    encodeURL: function(input) {
      let object = {};
      let hasOwnProperty = object.hasOwnProperty;

      let INDEX_BY_CODE_POINT = {'152':24,'160':32,'164':36,'166':38,'167':39,'169':41,'171':43,'172':44,'173':45,'174':46,'176':48,'177':49,'181':53,'182':54,'183':55,'187':59,'1025':40,'1026':0,'1027':1,'1028':42,'1029':61,'1030':50,'1031':47,'1032':35,'1033':10,'1034':12,'1035':14,'1036':13,'1038':33,'1039':15,'1040':64,'1041':65,'1042':66,'1043':67,'1044':68,'1045':69,'1046':70,'1047':71,'1048':72,'1049':73,'1050':74,'1051':75,'1052':76,'1053':77,'1054':78,'1055':79,'1056':80,'1057':81,'1058':82,'1059':83,'1060':84,'1061':85,'1062':86,'1063':87,'1064':88,'1065':89,'1066':90,'1067':91,'1068':92,'1069':93,'1070':94,'1071':95,'1072':96,'1073':97,'1074':98,'1075':99,'1076':100,'1077':101,'1078':102,'1079':103,'1080':104,'1081':105,'1082':106,'1083':107,'1084':108,'1085':109,'1086':110,'1087':111,'1088':112,'1089':113,'1090':114,'1091':115,'1092':116,'1093':117,'1094':118,'1095':119,'1096':120,'1097':121,'1098':122,'1099':123,'1100':124,'1101':125,'1102':126,'1103':127,'1105':56,'1106':16,'1107':3,'1108':58,'1109':62,'1110':51,'1111':63,'1112':60,'1113':26,'1114':28,'1115':30,'1116':29,'1118':34,'1119':31,'1168':37,'1169':52,'8211':22,'8212':23,'8216':17,'8217':18,'8218':2,'8220':19,'8221':20,'8222':4,'8224':6,'8225':7,'8226':21,'8230':5,'8240':9,'8249':11,'8250':27,'8364':8,'8470':57,'8482':25};
      
      let length = input.length;
      let index = -1;
      let codePoint;
      let pointer;
      let result = '';
      while (++index < length) {
        codePoint = input.charCodeAt(index);
        // “If `code point` is in the range U+0000 to U+007F, return a byte whose
        // value is `code point`.”
        if (codePoint >= 0x00 && codePoint <= 0x7F) {
          result += '%' + (codePoint<16?'0':'') + codePoint.toString(16);
          continue;
        }
        // “Let `pointer` be the index pointer for `code point` in index
        // `single-byte`.”
        if (hasOwnProperty.call(INDEX_BY_CODE_POINT, codePoint)) {
          pointer = INDEX_BY_CODE_POINT[codePoint];
          // “Return a byte whose value is `pointer + 0x80`.”
          result += '%' + (codePoint<16?'0':'') + (pointer + 0x80).toString(16);
        }
      }
      return result.toUpperCase();
    },
    writeToBuffer(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showToast("Скопійовано!");
      });
    },
    showToast(message) {
      this.$toasted.info(message, {
        position: 'top-center',
        duration: 1200,
        keepOnHover: false,
        theme: 'bubble'
      });
    }
  },
  computed: {
    totals: function () {
      return {
        total: this.loadedUsers.length,
        active: this.loadedUsers.filter(u => u.active).length,
        human: this.loadedUsers.filter(u => u.active && !u.bot).length,
        bot: this.loadedUsers.filter(u => u.active && u.bot).length,
        female: this.loadedUsers.filter(u => u.active && u.gender === 'female').length,
        male: this.loadedUsers.filter(u => u.active && u.gender === 'male').length
      };
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 640px){
  #tableContainer {
    width: 100vw!important;
  }
}

  .dollar {
    color: #0ea31c;
  }

  .coin {
    color: gold;
  }

  #tableContainer {
    display: flex;
    flex-direction: column;
    width: 60vw;
    vertical-align: top;
    font-size: 0.7em;
    color: white;
    height: 75vh;
    overflow-y: auto;
    text-align: left;
  }

  #tableContainer > table > tbody > tr > td {
    padding: 5px;
  }

  .deleteButton {
    border-radius: 50%;
    color: white;
    border: none;
    width: 25px;
    height: 25px;
    background-color: gray;
  }

  .telegramButton {
    color: #13599d;
    border: 1px solid transparent;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 1.7vw;
  }

  .telegramButton:hover {
    border: 1px solid greenyellow;
  }

  .deleteButton:hover {
    background-color: red;
  }

  .activeInput {
    margin-right: 5px;
  }

  .genderIcon {
    font-weight: 600;
    font-size: 1.7em;
  }

  .maleIcon {
    color: blue;
  }

  .femaleIcon {
    color: crimson;
  }

  .totals {
    width: 70%;
    color: #abadd0;
    float: right;
  }

  .totals table {
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
</style>