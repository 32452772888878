<template>
  <div class="calendar">
    <vue-confirm-dialog></vue-confirm-dialog>
    <div class="controls">
      <i class="far fa-arrow-alt-circle-left monthControl" @click="showedDate = new Date(showedDate.setMonth(showedDate.getMonth()-1))"></i>
      <div class="showedDateLabel">
        <div class="showedYear">{{showedDate.getFullYear()}}</div>
        <div class="showedMonth">{{monthNames[showedDate.getMonth()]}}</div>
      </div>
      <i class="far fa-arrow-alt-circle-right monthControl" @click="showedDate = new Date(showedDate.setMonth(showedDate.getMonth()+1))"></i>
    </div>
    <Loader v-if="loading"></Loader>
    <div v-else style="display: flex; flex-direction: row; width: 100%;">
      <div class="totals">
        Всього - {{statsData.totals.total}}<br>
        Люди - {{statsData.totals.humans}} | Боти - {{statsData.totals.bots}}<br><br>
        <i class="fas fa-exclamation-triangle" style="color: #b90200"> Помилка - </i>  {{statsData.totals.error}}<br>
        <i class="far fa-clock" style="color: #ffdc00"> Заплановано - </i>  {{statsData.totals.planned}}<br>
        <i class="far fa-calendar-check" style="color: #009d74"> Опубліковано - </i>  {{statsData.totals.published}}<br>
        <i class="fas fa-check" style="color: #99bf1a"> Перевірка 1 - </i>  {{statsData.totals.check1}}<br>
        <i class="fas fa-check-double" style="color: #11ff00"> Перевірка 2 - </i>  {{statsData.totals.check2}}<br>
      </div>


      <div class="container">
        <div class="row weekdayNames">
          <div v-for="(day, index) in dayOfWeekNames" :key="index" class="col ">
            {{day}}
          </div>
        </div>
        <div
            class="row week"
            v-for="(week, weekIndex) in weeks"
            :key="weekIndex">
          <div
              class="col day"
              v-for="(day, dayIndex) in week"
              :key="dayIndex">
            <template v-if="day.getMonth() == showedDate.getMonth()">
              <div class="dayNumber"
                :class="{beforeTodayNumber : isDateBeforeToday(day), afterTodayNumber : isDateAfterToday(day)}">
                {{day.getDate()}}
              </div>
              <div class="dayInfoContainer">
                <div class="dayTotals" v-if="valueFor(day, 'total') !== ''">
                  {{valueFor(day, 'total')}} <template v-if="valueFor(day, 'humans') !== ''"><i class="fas fa-user humanAccount" style="font-size: 0.5em; color: orange;">({{valueFor(day, 'humans')}})</i></template>
                </div>
                <div class="dayStatus">
                  <i
                      v-if="valueFor(day, 'error') !== ''"
                      class="fas fa-exclamation-triangle"
                      style="color: #b90200"
                  >
                     {{valueFor(day, 'error')}}
                  </i>
                  <i
                      v-if="valueFor(day, 'planned') !== ''"
                      class="far fa-clock"
                      style="color: #ffdc00"
                  >
                    {{valueFor(day, 'planned')}}
                  </i>
                  <i
                      v-if="valueFor(day, 'published') !== ''"
                      class="far fa-calendar-check"
                      style="color: #009d74"
                  >
                    {{valueFor(day, 'published')}}
                  </i>
                  <i
                      v-if="valueFor(day, 'check1') !== ''"
                      class="fas fa-check"
                      style="color: #99bf1a"
                  >
                    {{valueFor(day, 'check1')}}
                  </i>
                  <i
                      v-if="valueFor(day, 'check2') !== ''"
                      class="fas fa-check-double"
                      style="color: #11ff00"
                  >
                    {{valueFor(day, 'check2')}}
                  </i>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import api from "@/api";
import Loader from "@/components/Loader";

export default {
  name: "Calendar",
  components: {Loader},
  data() {
    return {
      monthNames: ["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"],
      dayOfWeekNames: ["Пн","Вт","Ср","Чт","Пт","Сб","Нд"],
      showedDate: new Date(),
      statsData: null,
      loading: false
    }
  },
  created() {
    this.reloadStatsData();
  },
  methods: {
    async reloadStatsData() {
      this.loading = true;
      await api.getStats(this.showedDate.getFullYear(), this.showedDate.getMonth() + 1).
      then(result => this.statsData = result.data);
      this.loading = false;
    },
    weekCount(year, month_number) {
      let firstOfMonth = new Date(year, month_number, 1);
      let lastOfMonth = new Date(year, month_number + 1, 0);

      let used = firstOfMonth.getDay() + 6 + lastOfMonth.getDate();

      return Math.ceil( used / 7) - 1;
    },
    totalsForDate(d) {
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      let key = [year, month, day].join('-');
      let totals = this.statsData.days[key];
      if (totals == null)
        totals = {
          total: 0,
          humans: 0,
          bots: 0,
          error: 0,
          planned: 0,
          published: 0,
          check1: 0,
          check2: 0
        }
      return totals;
    },
    valueFor(d, k) {
      let v = this.totalsForDate(d)[k];
      return v == 0 ? "" : v;
    },
    isDateBeforeToday(date) {
      return new Date(date.toDateString()) < new Date(new Date().toDateString());
    },
    isDateAfterToday(date) {
      return new Date(date.toDateString()) > new Date(new Date().toDateString());
    },
    isDateEqualsToday(date) {
      return new Date(date.toDateString()) === new Date(new Date().toDateString());
    }
  },
  watch: {
    showedDate: function () {
      this.reloadStatsData();
    }
  },
  computed: {
    weeks: function () {
      let firstOfMonth = new Date(this.showedDate.getFullYear(), this.showedDate.getMonth(), 1);
      let offset = firstOfMonth.getDay() == 0 ? 6 : firstOfMonth.getDay()-1;
      let weeks = [];
      for (let i = 0; i < this.weekCount(this.showedDate.getFullYear(), this.showedDate.getMonth()); i++) {
        let week = [];
        for (let j = 0; j < 7; j++) {
          week.push(new Date(this.showedDate.getFullYear(), this.showedDate.getMonth(), 1 - offset + i*7 + j));
        }
        weeks.push(week);
      }
      return weeks;
    }
  }
}
</script>

<style scoped>

.day {
  color: aliceblue;
  font-size: 1.5vw;
  height: 100px;
  border: 1px solid #888888;
  padding-left: 0.1vw;
  padding-right: 0.1vw;
  display: flex;
  flex-direction: row;
  margin: 3px;
}

.dayNumber {
  background-color: #31712e;
  height: 100%;
  min-width: 27px;
  line-height: 100%;
  text-align: center;
  font-family: "Cambria Math";
  font-weight: bold;
  font-size: 0.8em;
  color: #ccc;
  padding: 2px;
}

.beforeTodayNumber {
  background-color: #31312e!important;
  font-weight: normal;
}

.afterTodayNumber {
  background-color: #31316e!important;
  font-weight: normal;
}

.dayInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Roboto Mono', monospace;
}

.dayTotals {
  text-align: center;
  width: 100%;
  font-size: 1.15em;
  font-weight: bold;
  color: #f0f4f1;
}

.dayStatus {
  font-size: 0.7em;
  font-family: 'Roboto Mono', monospace;
  text-align: center;
}

.dayStatus i {
  padding: 1px;
}


.humanAccount {
  color: orange;
}

.botAccount {
  color: #00ff05;
}

.totalsAccount {
  color: #cceeff;
}





.otherMonth i {
  color: #888888 !important;
}

.otherMonth {
  color: #888888 !important;
}

.totals {
  padding: 10px;
  font-size: 1.4em;
  text-align: right;
  border-radius: 20px;
  color: #cceeff;
}

.totals thead {
  font-size: 1.1em;
}

.totals tbody {
  font-size: 1.8em;
}

@media screen and (min-width: 1000px) {


  .review {
    font-family: "Calibri Light";
    font-size: 0.9vw;
    margin: 0.15vw;
    cursor: pointer;
    font-weight: 100;
    color: #abadd0;
  }

  .reviewsList {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 10vh;
    min-width: 77%;
  }

  .dayInfo {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #13599d;
    max-width: 23%;
    min-width: 23%;
    height: 100%;
    font-size: 1.1vw;
    text-align: center;
  }

  .dayInfo i {
    font-size: 1.1vw;
  }

  .weekdayNames {
    color: #cceeff;
    font-size: 1.3vw;
    text-align: center;
  }

  /* Start of calendar controls */

  .calendar {
    height: 100%;
    padding: 1vw;
  }

  .controls {
    color: white;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 1vw;
  }

  .monthControl {
    font-weight: 500;
    font-size: 3vw;
    cursor: pointer;
    margin: 0 5vw;
  }

  .monthControl:hover {
    color: #82c175;
  }

  .showedDateLabel {
    margin: 0 1vw;
    min-width: 10vw;
  }

  .showedYear {
    font-size: 1vw;
    font-weight: 300;
  }

  .showedMonth {
    font-size: 1.5vw;
    font-weight: 600;
  }
}

@media screen and (max-width: 640px) {

  .day {
    padding-bottom: 5vh;
  }

  .reviewsList {
    display: none;
  }

  /* Start of calendar controls */

  .calendar {
    width: 100%;
    height: 100%;
    padding: 1vw;
  }

  .controls {
    color: white;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5vw 1vw;
    width: 100%;
  }

  .monthControl {
    font-weight: 500;
    font-size: 10vw;
    cursor: pointer;
    margin: 0 5vw;
  }

  .monthControl:active {
    color: #82c175;
  }

  .showedDateLabel {
    margin: 0 1vw;
    min-width: 10vw;
  }

  .showedYear {
    font-size: 4vw;
    font-weight: 300;
  }

  .showedMonth {
    font-size: 5.5vw;
    font-weight: 600;
  }
}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
}

</style>