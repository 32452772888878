<template>
  <div id="reviews">
    <ReviewPacksList
      @showPack="showPack" @addNewPack="addNewPack" :reloadList="reloadList" >
    </ReviewPacksList>
    <Loader v-if="loading"></Loader>
    <AddNewPack v-else-if="newPack" @savePack="savePack"></AddNewPack>
    <ReviewPack v-else-if="currentReviewPack" :reviewPack="currentReviewPack" @deletePack="deletePack"></ReviewPack>
  </div>
</template>

<script>
import ReviewPack from "@/components/reviews/ReviewPack";
import ReviewPacksList from "@/components/reviews/ReviewPacksList";
import AddNewPack from "@/components/reviews/AddNewPack";
import api from "@/api";
import Loader from "@/components/Loader";
export default {
  name: "Reviews",
  data() {
    return {
      reloadList: false,
      loading: false,
      newPack: false,
      currentReviewPack: null
    }
  },
  components: {Loader, AddNewPack, ReviewPacksList, ReviewPack},
  methods: {
    async deletePack(id) {
      this.loading = true;
      await api.deleteReviewPack(id).then();
      this.reloadList = true;
      this.newPack = false;
      this.currentReviewPack = null;
      this.loading = false;
    },
    async savePack(reviewPack) {
      this.loading = true;
      await api.saveReviewPack(reviewPack).then(
          response => {
            this.currentReviewPack = response.data;
          }
      );
      this.reloadList = true;
      this.newPack = false;
      this.loading = false;
    },
    addNewPack() {
      this.newPack = true;
    },
    async showPack(id) {
      this.loading = true;
      await api.getReviewPack(id).then(
          response => {
            this.currentReviewPack = response.data;
          }
      );
      this.newPack = false;
      this.loading = false;
    }
  }
}
</script>

<style scoped>
  #reviews {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 10%;
  }
</style>