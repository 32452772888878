<template>
  <div id="reviewPacksList">
    <div class="searchFormContainer">
      <b-form-input id="searchPack" type="search" v-model="search"></b-form-input>
      <i class="fa fa-search"></i>
    </div>
    <div class="filters">
      <b-checkbox class="filterCheckbox" v-model="filters.proceeding" button>
        <i class="fas fa-play" style="color: coral;"
           v-bind:class="{inactiveLabel : !filters.proceeding}"></i>
      </b-checkbox>
      <b-checkbox class="filterCheckbox" v-model="filters.pending" button>
        <i class="fas fa-hourglass-start" style="color: #ffdc00;"
           v-bind:class="{inactiveLabel : !filters.pending}"></i>
      </b-checkbox>
      <b-checkbox class="filterCheckbox" v-model="filters.finished" button>
        <i class="fas fa-lock" style="color: #0ea31c;"
           v-bind:class="{inactiveLabel : !filters.finished}"></i>
      </b-checkbox>
      <b-checkbox class="filterCheckbox" v-model="filters.errors" button>
        <i class="fas fa-exclamation-triangle" style="color: #b90200;"
           v-bind:class="{inactiveLabel : !filters.errors}"></i>
      </b-checkbox>
    </div>
    <div class="tags">
      <div class="filterTag"
           v-bind:class="{grayTag : !showWithNoTag}"
           @click="showWithNoTag = !showWithNoTag">
        Без тегів
      </div>
      <div class="filterTag"
         v-for="tag in this.tags"
         :key="tag.name"
         v-bind:class="{grayTag : !tag.active}"
        @click="tag.active = !tag.active">
        {{tag.name}}
      </div>
    </div>
    <Loader v-if="loading"></Loader>
    <div v-else class="packListContainer">
      <div v-for="pack in reviewPacks"
           class="pack"
           :class="{
          'pendingPack' : pack.totalReviews === 0,
          'proceedingPack' : pack.totalReviews !== pack.publishedReviews,
          'finishedPack' : pack.totalReviews !== 0 && pack.totalReviews === pack.publishedReviews
        }"
           :key="pack.id"
           @click="$emit('showPack', pack.id)">
        <div class="clientLabel">{{pack.client}}</div>
        <div class="packResultsContainer">
          <div>
            <div class="creationDate">{{pack.created}}</div>
            <div class="tagsContainer">
              <div class="tag" v-for="tag in pack.tags"
                   :key="tag">
                {{tag}}
              </div>
            </div>
          </div>
          <div class="packResults">
            {{pack.publishedReviews}} / {{pack.totalReviews}}
            <i class="fas fa-exclamation-triangle" style="color: #b90200; text-shadow: white 1px 1px 3px!important;"
               v-if="pack.containsErrors"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!loading" class="pack addNewPack" @click="$emit('addNewPack')">
      <i class="fa fa-plus"></i>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import Loader from "@/components/Loader";

export default {
  name: "ReviewPacksList",
  props: ['reloadList', 'updatePackInfo'],
  components: {Loader},
  watch: {
    updatePackInfo: async function(val) {
      await api.getPackInfo(val).then(response => {
        let index = this.loadedReviewPacks.findIndex(r => r.id === val);
        this.loadedReviewPacks.splice(index, 1, response.data);
        index = this.reviewPacks.findIndex(r => r.id === val);
        this.reviewPacks.splice(index, 1, response.data);
      });
      this.updatePackInfo = null;
    },
    reloadList: async function (val) {
      if (val) {
        await this.reloadTags();
        await this.reloadPacks();
        this.$emit("loaded");
      }
    },
    search: function () {
      this.filterPacks();
    },
    filters: {
      handler() {
        this.filterPacks();
      },
      deep: true
    },
    tags: {
      handler() {
        this.filterPacks();
      },
      deep: true
    },
    showWithNoTag: function () {
      this.filterPacks();
    }
  },
  data() {
    return {
      loadedReviewPacks: [],
      reviewPacks: [],
      loading: false,
      search: '',
      filters: {
        pending: true,
        proceeding: true,
        finished: true,
        errors: true
      },
      tags: [],
      showWithNoTag: true
    }
  },
  created() {
    this.reloadTags();
    this.reloadPacks();
  },
  methods: {
    async reloadTags() {
      await api.getTags().then(response => {
        this.tags = response.data.map(tag => ({name: tag, active: true}))
      })
    },
    async reloadPacks() {
      this.loading = true;
      await api.getPacks().then(
          response => {
            this.loadedReviewPacks = response.data;
            this.loadedReviewPacks.sort(
                (r1, r2) => {
                  if (r1.containsErrors || r2.containsErrors)
                    return r2.containsErrors - r1.containsErrors;
                  if (r1.totalReviews === 0 || r2.totalReviews === 0)
                    return r1.totalReviews - r2.totalReviews;
                  return (r1.publishedReviews / r1.totalReviews) - ((r2.publishedReviews / r2.totalReviews))
                });

            this.reviewPacks = this.loadedReviewPacks;
          }
      )
      this.loading = false;
    },
    filterPacks() {
      if (this.search.length < 3) {
        this.reviewPacks = this.loadedReviewPacks;
      } else {
        this.reviewPacks = this.loadedReviewPacks.filter(
            pack => pack.client.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        );
      }
      if (!this.filters.errors) {
        this.reviewPacks = this.reviewPacks.filter(
            pack => !pack.containsErrors
        );
      }
      if (!this.filters.pending) {
        this.reviewPacks = this.reviewPacks.filter(
            pack => pack.totalReviews != 0
        );
      }
      if (!this.filters.proceeding) {
        this.reviewPacks = this.reviewPacks.filter(
            pack => pack.totalReviews === 0 || pack.containsErrors || pack.totalReviews === pack.publishedReviews
        );
      }
      if (!this.filters.finished) {
        this.reviewPacks = this.reviewPacks.filter(
            pack => pack.totalReviews === 0 || pack.totalReviews !== pack.publishedReviews
        );
      }
      if (!this.showWithNoTag) {
        this.reviewPacks = this.reviewPacks.filter(
            pack => pack.tags.length
        )
      }
      this.tags.forEach(tag => {
        if(!tag.active) {
          this.reviewPacks = this.reviewPacks.filter(
              pack => !pack.tags.includes(tag.name)
          )
        }
      })
    }
  }
}
</script>

<style scoped>

  .filters {
    display: flex;
    justify-content: space-around;
    color: white;
    background-color: #2c3e50;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    color: white;
    background-color: #2c3e50;
  }

  .filterCheckbox {
    padding-right: 10px;
    visibility: hidden;
    display: none;
  }

  .filterCheckbox i {
    visibility: visible;
    display: block;
  }

  #reviewPacksList {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: hidden;
  }

  .packListContainer {
    overflow-y: auto;
  }

  .pack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    cursor: pointer;
    margin: 2px 3px;
    color: white;
    /*text-shadow: white 1px 1px 3px;*/
    border: 3px solid transparent;
    font-size: 0.75em;
  }

  .pack:hover {
    border: 3px solid crimson;
  }

  .addNewPack {
    background-color: springgreen;
    align-items: center;
    font-size: 2.0em;
  }

  .pendingPack {
    background-color: #ffdc00;
  }

  .proceedingPack {
    background-color: #ff703c;
  }

  .finishedPack {
    background-color: #005d07;
  }

  .clientLabel {
    font-size: 1.7em;
    align-self: flex-start;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .creationDate {
    font-size: 1.1em;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
  }

  .packResults {
    font-size: 1.9em;
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    flex-basis: min-content;
  }

  .packResultsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .searchFormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .searchFormContainer i {
    font-size: 2em;
    text-align: center;
    cursor: pointer;
  }

  #searchPack {
    margin: 5px;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.5);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.7);
  }

  .inactiveLabel {
    color: dimgrey!important;
  }

  .tagsContainer {
    width: 100%;
    padding: 3px 5px;
    display: flex;
    flex-wrap: wrap;
  }

  .tag {
    border-radius: 4px;
    background-color: #0a9dd0;
    margin: 1px;
    padding: 2px 4px;
    font-size: 1.15em;
    font-family: 'Oxygen Mono', monospace;
    font-weight: bold;
  }

  .filterTag {
    cursor: pointer;
    border-radius: 4px;
    background-color: #0a9dd0;
    margin: 1px;
    padding: 2px 3px;
    font-size: 1em;
    font-family: 'Oxygen Mono', monospace;
    font-weight: bold;
  }

  .grayTag {
    background-color: #888888!important;
  }
</style>