<template>
  <div id="options">
    <b-form
        id="optionsForm"
        class="d-flex flex-column justify-content-between align-items-start"
        >
      <b-form-group
          class="col-12"
        id="clientGroup"
        label="Клієнт"
        label-size="sm"
        label-for="clientInput"
        label-cols=3
        content-cols=9
        :state="clientState"
        :invalid-feedback="clientInvalidFeedback"
      >
        <b-form-input id="clientInput" v-model="reviewPack.client" :state="clientState" size="sm" trim></b-form-input>
      </b-form-group>

      <b-form-group
          class="col-12"
        id="urlGroup"
        label="URL"
        label-size="sm"
        label-for="urlInput"
        label-cols=3
        content-cols=9
        :state="urlState"
        :invalid-feedback="urlInvalidFeedback"
      >
        <b-form-input id="urlInput" v-model="reviewPack.url" :state="urlState" size="sm" trim></b-form-input>
      </b-form-group>
      <b-form-group
          class="col-12"
        id="frequencyGroup"
        label="Частота"
        label-size="sm"
        label-for="frequencySlider"
        label-cols=3
        content-cols=9
        :state=true
        :valid-feedback="frequencyList[frequency]"
        >
        <b-form-input
          id="frequencySlider"
          type="range"
          min="0"
          max="4"
          size="sm"
          v-model="frequency"></b-form-input>
      </b-form-group>
      <b-form-group
          class="col-12"
        id="commentsGroup"
        label="Коментарі"
        label-size="sm"
        label-for="commentsArea"
        label-cols=3
        content-cols=9
        >
      <b-form-textarea
          id="commentsArea"
          v-model="reviewPack.comments"
          rows="3"
          max-rows="3"
          no-resize></b-form-textarea>
      </b-form-group>
      <div class="tagsContainer">
        <vue-tags-input
            v-model="tag"
            :tags="tags"
            :autocomplete-items="filteredItems"
            @tags-changed="newTags => tags = newTags"
        />
      </div>
      <div class="controlsContainer col-12 d-flex flex-row justify-content-between">
        <b-button
            variant="danger"
            class="col-4"
            @click="$emit('deletePack', reviewPack.id)"
            >
          Видалити
        </b-button>
        <b-button
            class="pauseButton"
          :class="{green : reviewPack.running, yellow : !reviewPack.running}"
          @click="reviewPack.running = !reviewPack.running">
          <i v-if="reviewPack.running" class="far fa-play-circle"></i>
          <i v-else class="far fa-pause-circle"></i>
        </b-button>
        <b-button
            v-if="reviewPack.id === null"
            variant="success"
            class="col-4"
            @click="$emit('savePack')">
          Зберегти
        </b-button>
        <b-button
            v-else
            variant="success"
            class="col-4"
            @click="$emit('updatePack')">
          Оновити
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import api from "@/api";

export default {
  components: {
    VueTagsInput
  },
  props: ['reviewPack'],
  name: "Options",
  data() {
    return {
      frequencyList: ["1 відгук/3 дні", "1 відгук/2 дні", "1 відгук/день", "2 відгуки/день", "3 відгуки/день"],
      frequencyValues: [
        {
          count: 1,
          delay: 3
        },
        {
          count: 1,
          delay: 2
        },
        {
          count: 1,
          delay: 1
        },
        {
          count: 2,
          delay: 1
        },
        {
          count: 3,
          delay: 1
        },
      ],
      frequency: 0,
      tag: '',
      tags: [],
      autocompleteItems: []
    }
  },
  created() {
    this.tags = this.reviewPack.tags.map(tag => ({text: tag}));
    this.reloadAutocompleteItems();
  },
  methods: {
    async reloadAutocompleteItems() {
      await api.getTags().then(
          response => {
            this.autocompleteItems = response.data.map(tag => ({text: tag}));
          }
      )
    },
    getCurrentFrequency() {
      return this.frequencyValues[this.frequency];
    }
  },
  watch: {
    tags: function () {
      this.reviewPack.tags = this.tags.map(tagInfo => tagInfo.text);
    }
  },
  computed: {
    filteredItems() {
      return this.autocompleteItems.filter(item => {
        return item.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
    clientState() {
      return this.reviewPack.client.length >= 4;
    },
    clientInvalidFeedback() {
      if (this.reviewPack.client.length > 0)
        return 'Мінімум 4 символи!';
      else
        return '';
    },
    urlState() {
      return this.reviewPack.url.length > 0 && this.reviewPack.url.indexOf("https://") >= 0;
    },
    urlInvalidFeedback() {
      if (!this.reviewPack.url.length)
        return 'Поле обов\'язкове!';
      if (this.reviewPack.url.indexOf("https://") < 0)
        return 'Невірний формат!';
      return '';
    }
  }
}
</script>

<style scoped>
  #options {
    width: 32%;
    padding: 20px;
  }

  #optionsForm {
    width: 100%;
  }

  .tagsContainer {
    width: 100%;
    padding: 3px 5px;
    display: block;
  }

  .green {
    color: #00ff05;
  }

  .yellow {
    color: #ffdc00;
  }

  .pauseButton {
    background-color: #2c3e50;
    padding: 0.1rem;
    font-size: 2rem;
    line-height: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    outline: none;
    border: none;
  }

  .pauseButton:hover {
    font-size: 2.1rem;
  }

  .pauseButton:active {
    box-shadow: none!important;
    outline: none;
    border: none;
  }

  .pauseButton:focus {
    box-shadow: none!important;
    outline: none;
    border: none;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }

  .col-12 {
    color: #009fd0;
  }
</style>