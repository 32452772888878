<template>
  <div id="newUserContainer">
      <Loader v-if="loading"></Loader>
      <b-form v-else id="formContent" @submit="$emit('create-user', newUserData)">
        <b-form-group
          id="mode"
          label="Режим"
          label-size="sm"
          label-for="switchMode"
          label-cols="3"
          content-cols="3"
        >
          <b-form-checkbox id="switchMode" switch size="sm" v-model="packMode">
            {{packMode ? 'Файл' : 'Вручну'}}
          </b-form-checkbox>
        </b-form-group>

        <template v-if="packMode">
          <div class="instruction">
            <ul>
              <li>1) У цьому режимі додавайте тільки ботів</li>
              <li>2) Підготуйте в Excel таблицю користувачів з наступними полями:
                <ul>
                  <li>Ім'я користувача</li>
                  <li>Пароль</li>
                  <li>Логін</li>
                  <li>Номер телефону (380ХХХХХХХХХ)</li>
                  <li>Стать (female/male)</li>
                  <li>Англійською (1-так/0-ні)</li>
                  <li>К-ть відгуків</li>
                </ul>
              </li>
              <li>3) Збережіть файл в форматі .csv і завантажте через форму нижче</li>
            </ul>
          </div>

          <b-form-file
              v-model="file"
              placeholder="Оберіть файл"
              drop-placeholder="Перетягніть сюди"
              style="max-width: 65%; width: 65%"
              accept=".csv"
          ></b-form-file>
          <b-button v-if="file" class="uploadButton" @click="processFile" variant="success">Завантажити</b-button>

        </template>

        <template v-else>
          <div class="instruction">
            <ul>
              <li>1) Додаємо нового користувача через форму нижче</li>
              <li>2) Новий користувач повинен знайти в Telegram бота
                <a href="https://t.me/GMFeedbackNotificationBot" style="color: #ffffff" target="_blank">
                  <code>@GMFeedbackNotificationBot</code>
                </a>
              </li>
              <li>3) Боту потрібно надіслати свій логін, після цього користувач
                зможе отримувати замовлення на відгуки</li>
            </ul>
          </div>


          <b-form-group
            valid-feedback=""
            :invalid-feedback="loginInvalidFeedback"
            :state="loginState"
            label="Логін"
            label-size="sm"
            label-for="login"
            label-cols="3"
            content-cols=5
          >
            <b-input
                id="login"
                v-model="newUserData.login"
                type="text"
                placeholder="Логін"
                size="sm"
                required>
            </b-input>
          </b-form-group>


          <b-form-group
              valid-feedback=""
              :invalid-feedback="nameInvalidFeedback"
              :state="nameState"
              label="Повне ім'я"
              label-for="fullName"
              label-size="sm"
              label-cols="3"
              content-cols=5
          >
            <b-input
                id="fullName"
                v-model="newUserData.fullName"
                type="text"
                size="sm"
                placeholder="Повне ім'я"
                required>
            </b-input>
          </b-form-group>

          <b-form-group
            label="Стать"
            label-size="sm"
            label-cols="3"
            content-cols="5"
          >
            <input
                @click="newUserData.gender='male'"
                class="hiddenRadio"
                id="maleGender"
                type="radio">
            <label for="maleGender">
              <i
                  v-bind:class="{grayGender : newUserData.gender !== 'male'}"
                  class="fa fa-mars maleGender"
              >
              </i>
            </label>
            <input
                @click="newUserData.gender = 'female'"
                class="hiddenRadio"
                id="femaleGender"
                type="radio">
            <label for="femaleGender">
              <i
                  v-bind:class="{grayGender : newUserData.gender !== 'female'}"
                  class="fa fa-venus femaleGender"
              >
              </i>
            </label>
          </b-form-group>


          <b-form-group
              label="Тип"
              label-size="sm"
              label-cols="3"
              content-cols="5"
          >
            <input
                @click="newUserData.bot = false"
                class="hiddenRadio"
                id="humanAccount"
                type="radio">
            <label for="humanAccount">
              <i
                  v-bind:class="{grayAccount : newUserData.bot}"
                  class="fas fa-user humanAccount"
              >
              </i>
            </label>
            <input
                @click="newUserData.bot = true"
                class="hiddenRadio"
                id="botAccount"
                type="radio">
            <label for="botAccount">
              <i
                  v-bind:class="{grayAccount : !newUserData.bot}"
                  class="fas fa-robot botAccount"
              >
              </i>
            </label>
          </b-form-group>

          <template v-if="newUserData.bot">
            <b-form-group
                label="К-сть відгуків"
                label-size="sm"
                label-cols="3"
                content-cols="5"
            >
              <b-form-input
                  type="number"
                  v-model="newUserData.feedbackCount"
                  min="0"
                  max="500"
              >

              </b-form-input>
            </b-form-group>

            <b-form-group
                label="Пароль"
                label-size="sm"
                label-cols="3"
                content-cols="5"
            >
              <b-form-input
                  type="text"
                  v-model="newUserData.password"
              >
              </b-form-input>
            </b-form-group>

            <b-form-group
                label="Телефон"
                label-size="sm"
                label-cols="3"
                content-cols="5"
            >
              <b-form-input
                  type="text"
                  v-model="newUserData.phone"
              >
              </b-form-input>
            </b-form-group>

            <b-form-group
                label="Англійський"
                label-size="sm"
                label-cols="3"
                content-cols="5"
            >
              <b-form-checkbox
                  v-model="newUserData.english"
                  size="lg"
              >
              </b-form-checkbox>
            </b-form-group>

          </template>

          <b-button type="submit" variant="primary">Додати</b-button>
        </template>

      </b-form>
  </div>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: "UserForm",
  props: ["msg"],
  data() {
    return {
      newUserData : {
        login: "",
        fullName: "",
        gender: "male",
        bot: false,
        feedbackCount: 0,
        password: "",
        phone: "",
        english: false
      },
      loading: false,
      packMode: false,
      file: null,
      fileData: []
    }
  },
  watch: {
    msg() {
      this.newUserData = {
        login: "",
        fullName: "",
        gender: "male",
        bot: false,
        feedbackCount: 0,
        password: "",
        phone: "",
        english: false
      };
      this.file = null;
      this.fileData = null;
    }
  },
  methods: {
    processFile: function () {
      let reader = new FileReader();
      let vm = this;
      reader.onload = function (e) {
        vm.writeFileData(e.target.result);
      }
      reader.readAsText(this.file, "Windows-1251");
    },
    writeFileData: function (data) {
      let lines = data.split("\n");
      for (let i = 1; i < lines.length; i++) {
        if (lines[i].length < 2) {
          continue;
        }
        let fields = lines[i].split(";");
        let user = {
          login: fields[2].length > 2 ? fields[2] : function(){throw new Error('Логін закороткий')},
          fullName: fields[0].length > 2 ? fields[0] : function(){throw new Error('Ім\'я закоротке')},
          gender: (fields[4] === 'male' || fields[4] === 'female') ? fields[4] : function(){throw new Error('Стать неправильно вказана')},
          bot: true,
          feedbackCount: parseInt(fields[6]) >= 0 ? parseInt(fields[6]) : function(){throw new Error('Помилка в к-сті відгуків')},
          password: fields[1],
          phone: fields[3],
          english: fields[5] === '1'
        };
        try {
          for (const value of Object.values(user)) {
            if (typeof value == 'function')
              value();
          }
        } catch (err) {
          this.$confirm(
              {
                title: 'Помилка в рядку №' + i,
                message: err.message,
                button: {
                  yes: 'OK'
                }
              }
          )
          this.file = null;
          return;
        }
        this.fileData.push(user);
      }
      this.$emit('save-pack', this.fileData);
    }
  },
  computed: {
    loginState() {
      let login = this.newUserData.login;
      return login.length > 4 && /^[a-zA-Z][a-zA-Z0-9-_.@]{4,35}$/.test(login);
    },
    loginInvalidFeedback() {
      let login = this.newUserData.login;
      if (!login.length) {
        return "Поле обов'язкове";
      } else if (login.length <= 4) {
        return "Мінімум 5 символів";
      } else if (!/^[a-zA-Z][a-zA-Z0-9-_.@]{4,35}$/.test(login)) {
        return "Тільки латиниця, цифри і - _ . @";
      } else {
        return "";
      }
    },
    nameState() {
      let name = this.newUserData.fullName;
      return name.length > 4;
    },
    nameInvalidFeedback() {
      let name = this.newUserData.fullName;
      if (!name.length) {
        return "Поле обов'язкове";
      } else {
        return "Мінімум 5 символів";
      }
    }
  },
  components: {Loader}
}
</script>

<style scoped>
#newUserContainer {
  display: flex;
  flex-direction: column;
  float: right;
  margin: 30px;
  min-width: 30vw;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
}

li {
  list-style: none;
  padding-bottom: 10px;
}

.instruction {
  display: inline-block;
  font-weight: 100;
  font-size: 0.7em;
  width: 400px;
  vertical-align: top;
}

#newUserForm {
  display: inline-block;
}

.labelCell {
  text-align: right;
}

.inputCell {
  padding: 5px;
}

.errorCell {
  font-style: italic;
  color: orangered;
  font-size: 0.8em;
  font-weight: 200;
}

.inputCell > input[type=text] {
  width: 150px;
}

.addButton {
  font-size: 16px;
  font-weight: 600;
  margin: 10px;
}

.maleGender {
  color: blue;
  font-weight: 600;
  font-size: 1.8em;
}

.femaleGender {
  color: crimson;
  font-weight: 600;
  font-size: 1.8em;
}

.grayGender {
  color: dimgrey!important;
}


.humanAccount {
  color: orange;
  font-weight: 600;
  font-size: 1.8em;
}

.botAccount {
  color: #00ff05;
  font-weight: 600;
  font-size: 1.8em;
}

.grayAccount {
  color: dimgrey!important;
}


.hiddenRadio {
  visibility: hidden;
}

#formContent {
  color: aquamarine;
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.col-form-label {
  font-size: 1.5em;
}

.uploadButton {
  width: 30%;
  margin: 35px auto;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

</style>