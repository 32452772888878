<template>
  <div id="stats">
    <Loader v-if="loading"></Loader>
    <template v-else>
      <DeletedReviewsTable :statsData="statsData"></DeletedReviewsTable>
    </template>
  </div>
</template>

<script>

import Loader from "@/components/Loader";
import DeletedReviewsTable from "@/components/stats/DeletedReviewsTable.vue";
import api from "@/api";
export default {
  name: "Stats",
  components: {DeletedReviewsTable, Loader},
  created() {
    this.reloadStatsData();
  },
  data() {
    return {
      statsData : [],
      loading : false
    }
  },
  methods: {
    reloadStatsData : async function () {
      this.loading = true;
      await api.getDeletedReviews()
          .then(response => {
            this.statsData = response.data;
          });
      this.loading = false;
    }
  }
}
</script>

<style scoped>

#stats {
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
</style>