<template>
  <div id="statsTableContainer">
    <table class="table table-dark table-striped">
      <thead>
        <tr>
          <th>#</th>
          <th>Клієнт</th>
          <th>Дата публікації</th>
          <th>Відгук</th>
          <th>Користувач</th>
          <th>Статус</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(stats, index) in statsData" :key="stats.id">
          <td>{{index + 1}}</td>
          <td>
            <a :href="stats.url" target="_blank">
              {{stats.client}}
            </a>
          </td>
          <td>{{stats.postDate}}</td>
          <td><p class="reviewText" :title="stats.fullReview">{{stats.shortReview}}</p></td>
          <td>{{stats.user}}</td>
          <td>
            <div class="status" :style="statusDataFor(stats.status).style">
              {{statusDataFor(stats.status).status}}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props : ["statsData"],
  name: "StatsTable",
  methods: {
    formatDate: function (date) {
      if (date == null || typeof date == 'undefined' ) {
        return "";
      } else {
        let hours = new Date(date).getHours();
        let minutes = new Date(date).getMinutes();
        let result = hours + ":" + (minutes > 9 ? minutes : "0" + minutes);
        return result;
      }
    },
    statusDataFor(status) {
      switch (status) {
        case -1:
          return {
            status: "Помилка",
            style: "background-color: #a50"
          }
        case 0:
          return {
            status: "Новий",
            style: "background-color: #aaa"
          }
        case 1:
        case 2:
          return {
            status: "Збережено",
            style: "background-color: #f60"
          }
        case 3:
        case 4:
        case 5:
          return {
            status: "Заплановано",
            style: "background-color: #cc0"
          }
        case 6:
          return {
            status: "Опубліковано",
            style: "background-color: #2a5"
          }
        case 7:
          return {
            status: "Перевірка 1",
            style: "background-color: #2c5"
          }
        case 8:
          return {
            status: "Перевірка 2",
            style: "background-color: #2f5"
          }
      }
    }
  }
}
</script>

<style scoped>
  #statsTableContainer {
    display: flex;
    flex-direction: row;
    vertical-align: top;
    font-size: 1.0vw;
    color: white;
    height: 72vh;
    overflow-y: auto;
    padding: 10px;
    text-align: left;
  }

  .done {
    font-size: 1.5vw;
    color: #00ff05;
  }

  .reviewText {
    font-family: 'Roboto Mono', monospace;
  }

  .status {
    border-radius: 10px;
    text-align: center;
    padding: 3px;
    font-size: 1.1em;
    color: #2c3e50;
  }
</style>