<template>
  <div id="reviews">
    <vue-confirm-dialog></vue-confirm-dialog>
    <ReviewPacksList
        @showPack="showPack"
        @addNewPack="addNewPack"
        @loaded="reloadList = false"
        :reloadList="reloadList"
        :updatePackInfo="updatePackId">
    </ReviewPacksList>
    <Loader v-if="loading"></Loader>
    <ReviewPack
        v-else-if="currentReviewPack"
        :review-pack="currentReviewPack"
        @reload-list="reloadList = true"
        @deletePack="deletePack"
        @savePack="savePack"
        @updatePack="updatePack"
        @changeUser="changeUser"
        @deleteReview="deleteReview"
        @updateReview="updateReview"
        @reschedule="reschedule">
    </ReviewPack>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import ReviewPack from "@/components/reviews2/ReviewPack";
import api from "@/api";
import ReviewPacksList from "@/components/reviews/ReviewPacksList";
export default {

  name: "Reviews2",
  components: {ReviewPack, Loader, ReviewPacksList},
  data() {
    return {
      reloadList: false,
      loading: false,
      currentReviewPack: null,
      updatePackId: null
    }
  },
  methods: {
    async deletePack(id) {
      this.loading = true;
      await api.deleteReviewPack(id);
      this.reloadList = true;
      this.newPack = false;
      this.currentReviewPack = null;
      this.loading = false;
    },
    async savePack() {
      this.loading = true;
      await api.saveReviewPack(this.currentReviewPack).then(
          response => {
            console.log(response.data);
            this.currentReviewPack = response.data;
          }
      );
      this.reloadList = true;
      this.newPack = false;
      this.loading = false;
    },
    async updatePack() {
      this.loading = true;
      await api.updateReviewPack(this.currentReviewPack).then(
          response => {
            console.log(response.data);
            this.currentReviewPack = response.data;
          }
      );
      this.loading = false;
      this.updatePackId = this.currentReviewPack.id;
    },
    addNewPack() {
      if (this.currentReviewPack == null || this.currentReviewPack.id !== null) {
        this.currentReviewPack = {
          id: null,
          client: "",
          totalReviews: 0,
          publishedReviews: 0,
          created: new Date(),
          url: "",
          comments: "",
          tags: [],
          running: true,
          reviewList: [],
        }
      }
    },
    async showPack(id) {
      this.loading = true;
      await api.getReviewPack(id).then(
          response => {
            this.currentReviewPack = response.data;
          }
      );
      this.loading = false;
    },
    changeUser(review) {
      api.changeUserForReview(review)
          .then(response => {
            const index = this.currentReviewPack.reviewList.findIndex(r => r.id === review.id);
            this.currentReviewPack.reviewList.splice(index, 1, response.data);
            this.showToast("Користувача змінено!");
          });
    },
    async deleteReview(review) {
      if (review.status > 0) {
        console.log("deleting review from db");
        await api.deleteReview(review.id);
      }
      this.currentReviewPack.reviewList = this.currentReviewPack.reviewList.filter(r => r.id !== review.id);
      if (review.status > 0)
        this.updatePackId = this.currentReviewPack.id;
    },
    async updateReview(review) {
      await api.updateReview(review).then(
          response => {
            const index = this.currentReviewPack.reviewList.findIndex(r => r.id === review.id);
            this.currentReviewPack.reviewList.splice(index, 1, response.data);
            this.showToast("Відгук оновлено!");
          }
      );
    },
    async reschedule(review) {
      await api.rescheduleAfterError(review).then(
          response => {
            const index = this.currentReviewPack.reviewList.findIndex(r => r.id === review.id);
            this.currentReviewPack.reviewList.splice(index, 1, response.data);
            this.showToast("Відгук відновлено!");
          }
      );
      this.updatePackId = this.currentReviewPack.id;
    },
    showToast(message) {
      this.$toasted.info(message, {
        position: 'top-center',
        duration: 1200,
        keepOnHover: false,
        theme: 'bubble'
      });
    }
  }
}
</script>

<style scoped>
  #reviews {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 10%;
  }
</style>