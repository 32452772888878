<template>
  <div id="todayTasks">
    <vue-confirm-dialog></vue-confirm-dialog>
    <div class="formContainer">
      <b-form-radio-group v-model="listType" button-variant="outline-primary" size="lg" buttons>
        <b-form-radio name="listType" value="todayBot">Сьогоднішні (боти)</b-form-radio>
        <b-form-radio name="listType" value="todayHuman">Сьогоднішні (люди)</b-form-radio>
        <b-form-radio name="listType" value="yesterday">Позавчорашні</b-form-radio>
        <b-form-radio name="listType" value="weekAgo">Тижневі</b-form-radio>
      </b-form-radio-group>
      <b-form-checkbox v-model="showLateTasks" style="color: #cceeff; margin: 10px;">Показувати тільки прострочені завдання</b-form-checkbox>
    </div>
    <div class="tags">
      <div class="filterTag"
           v-bind:class="{grayTag : !showWithNoTag}"
           @click="showWithNoTag = !showWithNoTag">
        Без тегів
      </div>
      <div class="filterTag"
           v-for="tag in this.tags"
           :key="tag.name"
           v-bind:class="{grayTag : !tag.active}"
           @click="tag.active = !tag.active">
        {{tag.name}}
      </div>
    </div>
    <div class="table-container">
      <table class="tasks-table table table-dark table-striped">
        <thead>
          <th>Дата</th>
          <th>Клієнт</th>
          <th>Відгук</th>
          <th>Користувач</th>
          <th>Коментар</th>
          <th>Статус</th>
          <th style="min-width: 250px;"></th>
        </thead>
        <Loader v-if="loading"></Loader>
        <tbody v-else>
          <tr v-for="task in taskList" :key="task.id" class="taskRow">
            <td>{{task.postDate}}</td>
            <td>
              <i @click="writeToBuffer(task.url)" class="far fa-copy copyButton"></i>
              <a :href="task.url" class="clientName" target="_blank">{{task.client}}</a>
              <div class="tagsContainer">
                <div class="tag" v-for="tag in task.tags"
                     :key="tag">
                  {{tag}}
                </div>
              </div>
            </td>
            <td>
              <p
                  :title="task.fullReviewText"
                  style="cursor: pointer; font-family: 'Roboto Mono', monospace; display: flex"
                  @click="writeToBuffer(task.fullReviewText)">
                <i
                    class="fa" v-bind:class="{'fa-mars maleGender' : task.gender === 'male',
                  'fa-venus femaleGender' : task.gender === 'female',
                  'fa-venus-mars neutralGender' : task.gender === 'neutral'}"></i>
                 {{task.shortReviewText}}
              </p>
            </td>
            <td class="user-cell">
              <p  :title="task.userLogin"
                  style="cursor: pointer;"
                  @click="writeToBuffer(task.userName)">{{task.userName}}</p>
            </td>
            <td class="commentCell">
              <i class="fas fa-exclamation" style="color: #c95900" v-if="task.clientComment || task.comment"></i> {{task.clientComment}}<br>
              {{task.comment}}
            </td>
            <td>
              <div
                  class="status"
                  :style="getStyle(task.status)"
              >
                <i style="color: #00ff05" class="far fa-check-circle" v-if="task.status === expectedStatus"></i>
                <i style="color: #ffdc00" class="far fa-clock" v-else></i>
                {{statusNames[task.status]}}
              </div>
            </td>
            <td>
              <div class="actions">
                <b-form-input
                    class="commentInput"
                    type="text"
                    v-model="task.actualBot"
                    size="sm"
                    placeholder="Акаунт...">
                </b-form-input>
                <div class="action" @click="changeStatus(task)">
                  <template v-if="task.status === expectedStatus">
                    <p style="color: orange"><i class="fas fa-undo-alt"></i> Відмінити</p>
                  </template>
                  <template v-else>
                    <p  style="color: #82c175"><i class="fas fa-clipboard-check"></i> Виконати</p>
                  </template>
                </div>
                <div class="action" @click="reschedule(task)" v-if="listType !== 'yesterday' && listType !== 'weekAgo'">
                  <p style="color: skyblue"><i class="fas fa-history"></i> Відкласти</p>
                </div>
                <div class="action" @click="setErrorStatus(task)" v-else>
                  <p style="color: #c95900"><i class="fas fa-times"></i> Відгук відсутній</p>
                </div>
                <div class="action" @click="deleteTask(task)">
                  <p style="color: crimson"><i class="far fa-trash-alt"></i> Видалити</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import api from "@/api";
import Loader from "@/components/Loader";

export default {
  name: "TodayTasks",
  components: {Loader},
  data() {
    return {
      statusNames: [
        'Новий',
        'Збережений',
        'Збережений',
        'Запланований',
        'Час: ',
        'Надіслано',
        'Опубліковано',
        'Перевірено №1',
        'Перевірено №2'
      ],
      loading: false,
      listType : "todayBot",
      loadedTaskList : [],
      taskList : [],
      showLateTasks : false,
      tags : [],
      showWithNoTag : true
    }
  },
  computed: {
    expectedStatus: function () {
      switch (this.listType) {
        case "todayBot":
          return 6;
        case "todayHuman":
          return 6;
        case "yesterday":
          return 7;
        case "weekAgo":
          return 8;
        default:
          return 6;
      }
    }
  },
  watch: {
    listType: function () {
      this.reloadData();
    },
    showLateTasks: function () {
      this.filterPacks();
    },
    tags: {
      handler() {
        this.filterPacks();
      },
      deep: true
    },
    showWithNoTag: function () {
      this.filterPacks();
    }
  },
  created() {
    this.reloadTags();
    this.reloadData();
  },
  methods: {
    async reloadTags() {
      await api.getTags().then(response => {
        this.tags = response.data.map(tag => ({name: tag, active: true}))
      })
    },
    async reloadData() {
      this.loading = true;
      await api.getTodayTasks(this.listType).then(response => {
        this.loadedTaskList = response.data;
        this.taskList = this.loadedTaskList;
      });
      this.loading = false;
    },
    writeToBuffer(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showToast("Скопійовано!");
      });
    },
    getStyle(status) {
      switch (status) {
        case -1:
          return "color: #b90200";
        case 0:
          return "color: #aaa";
        case 1:
        case 2:
          return "color: #f60";
        case 3:
        case 4:
        case 5:
          return "color: #cc0";
        case 6:
          return "color: #1a4";
        case 7:
          return "color: #2e5";
        case 8:
          return "color: #3f6";
      }
    },
    async changeStatus(review) {
      if (review.status !== this.expectedStatus) {
        this.$confirm({
          message: 'Підтвердіть ' + (this.expectedStatus < 6 ? 'публікацію' : 'перевірку') +
              ' відгуку \'' + review.shortReviewText + '\' для ' + review.client,
          button: {
            yes: 'Так',
            no: 'Ні'
          },
          callback: confirm => {
            if (confirm) {
              api.changeTaskStatus(review.id, this.expectedStatus, review.actualBot).then(response => {
                const index = this.taskList.findIndex(task => task.id === review.id);
                this.taskList.splice(index, 1);
                this.taskList.push(response.data);
                this.showToast("Оновлено!");
              });
            }
          }
        });
      } else {
        api.changeTaskStatus(review.id, this.expectedStatus).then(response => {
          const index = this.taskList.findIndex(task => task.id === review.id);
          this.taskList.splice(index, 1);
          this.taskList.unshift(response.data);
          this.showToast("Оновлено!");
        });
      }
    },
    reschedule(review) {
      this.$confirm({
        message: 'У відгука \'' + review.shortReviewText + '\' буде видалено дату і присвоєного користувача. Продовжити?',
        button: {
          yes: 'Так',
          no: 'Ні'
        },
        callback: confirm => {
          if (confirm) {
            api.rescheduleTask(review.id).then(() => {
              const index = this.taskList.findIndex(task => task.id === review.id);
              this.taskList.splice(index, 1);
              this.showToast("Видалено!");
            });
          }
        }
      });
    },
    setErrorStatus(review) {
      this.$confirm({
        message: 'Відгук відсутній. Пізніше його можна відредагувати і запланувати на іншу дату. Продовжити?',
        button: {
          yes: 'Так',
          no: 'Ні'
        },
        callback: confirm => {
          if (confirm) {
            api.setTaskDeleted(review.id).then(() => {
              const index = this.taskList.findIndex(task => task.id === review.id);
              this.taskList.splice(index, 1);
              this.showToast("Видалено!");
            });
          }
        }
      });
    },
    deleteTask(review) {
      this.$confirm({
        message: 'Відгук \'' + review.shortReviewText + '\' буде видалено. Відмінити цю дію неможливо. Продовжити?',
        button: {
          yes: 'Так',
          no: 'Ні'
        },
        callback: confirm => {
          if (confirm) {
            api.deleteReview(review.id).then(() => {
              const index = this.taskList.findIndex(task => task.id === review.id);
              this.taskList.splice(index, 1);
              this.showToast("Видалено!");
            });
          }
        }
      });
    },
    showToast(message) {
      this.$toasted.info(message, {
        position: 'top-center',
        duration: 1200,
        keepOnHover: false,
        theme: 'bubble'
      });
    },
    filterPacks() {
      if (!this.showLateTasks) {
        this.taskList = this.loadedTaskList;
      } else {
        let curDate = new Date();
        switch (this.listType) {
          case "yesterday":
            curDate.setDate(curDate.getDate() - 1);
            break;
          case "weekAgo":
            curDate.setDate(curDate.getDate() - 7);
            break;
        }
        curDate = new Date(curDate.toDateString());
        this.taskList = this.loadedTaskList.filter(
            task => {
              let taskDate = new Date(new Date(task.postDate).toDateString());
              return taskDate.getTime() !== curDate.getTime();
            }
        );
      }
      if (!this.showWithNoTag) {
        this.taskList = this.taskList.filter(
            task => task.tags.length
        )
      }
      this.tags.forEach(tag => {
        if(!tag.active) {
          this.taskList = this.taskList.filter(
              task => !task.tags.includes(tag.name)
          )
        }
      })
    }
  }
}
</script>

<style scoped>
  .commentCell {
    word-break: break-word;
    max-width: 250px;
  }

  .table-container {
    width: 100%;
    height: 80vh;
    overflow-y: auto;
  }

  .tasks-table {
    width: 100%;
    color: lightblue;
  }

  .tasks-table thead {
    font-size: 1.1em;
    color: #009fd0;
  }

  .tasks-table tbody {

  }


  #todayTasks {
    padding: 10px;
  }

  .status {
    font-family: 'Roboto Mono', monospace;
    font-size: 1.1em;
    font-weight: 600;
  }

  td a {
    color: #0a9dd0;
  }

  .action {
    cursor: pointer;
  }

  .action:hover {
    filter: brightness(120%);
  }

  .actions {
    visibility: hidden;
  }

  .taskRow:hover .actions {
    visibility: visible;
  }

  .copyButton {
    cursor: pointer;
  }

  .copyButton:hover {
    opacity: 50%;
  }

  .formContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .neutralGender {
    color: greenyellow;
    font-weight: 600;
    font-size: 1.2em;
    cursor: pointer;
  }

  .maleGender {
    color: blue;
    font-weight: 600;
    font-size: 1.2em;
    cursor: pointer;
  }

  .femaleGender {
    color: crimson;
    font-weight: 600;
    font-size: 1.2em;
    cursor: pointer;
  }

  .tagsContainer {
    width: 100%;
    padding: 3px 5px;
    display: flex;
    flex-wrap: wrap;
    color: white;
  }

  .tag {
    border-radius: 4px;
    background-color: #045570;
    margin: 1px;
    padding: 1px 3px;
    font-size: 0.85em;
    font-family: 'Oxygen Mono', monospace;
    font-weight: normal;
  }

  .clientName {
    font-size: 1.15em;
    font-weight: bold;
    color: white;
    padding-left: 3px;
  }

  .tags {
    display: flex;
    flex-direction: row-reverse;
    padding: 3px;
    flex-wrap: wrap;
    color: white;
    background-color: #2c3e50;
  }

  .filterTag {
    cursor: pointer;
    border-radius: 4px;
    background-color: #0a9dd0;
    margin: 1px;
    padding: 2px 3px;
    font-size: 1em;
    font-family: 'Oxygen Mono', monospace;
    font-weight: bold;
  }

  .grayTag {
    background-color: #888888!important;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
</style>