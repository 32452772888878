<template>
  <div id="stats">
    <b-form
        class="filterForm justify-content-between"
        inline
        @submit="applyFilters"
        @reset="resetFilters">
      <b-form-datepicker
          id="dateFrom"
          v-model="filter.dateFrom"
          size="sm"
          class="dateField"></b-form-datepicker>
      -
      <b-form-datepicker
          v-model="filter.dateTo"
          size="sm"
          class="dateField"></b-form-datepicker>

      <b-form-select v-model="filter.client" :options="clients" size="sm"></b-form-select>

      <b-form-select size="sm" v-model="filter.statusFrom">
        <b-form-select-option value="-1">Помилка</b-form-select-option>
        <b-form-select-option value="3">Запланований</b-form-select-option>
        <b-form-select-option value="6">Опублікований</b-form-select-option>
        <b-form-select-option value="7">Перевірено №1</b-form-select-option>
        <b-form-select-option value="8">Перевірено №2</b-form-select-option>
      </b-form-select>
       -
      <b-form-select size="sm" v-model="filter.statusTo">
        <b-form-select-option value="-1">Помилка</b-form-select-option>
        <b-form-select-option value="3">Запланований</b-form-select-option>
        <b-form-select-option value="6">Опублікований</b-form-select-option>
        <b-form-select-option value="7">Перевірено №1</b-form-select-option>
        <b-form-select-option value="8">Перевірено №2</b-form-select-option>
      </b-form-select>


      <div class="selectType">
        <input
            @click="filter.userType === 'human' ? filter.userType = '' : filter.userType = 'human'"
            class="hiddenRadio"
            id="humanAccount"
            type="checkbox">
        <label for="humanAccount">
          <i
              v-bind:class="{grayAccount : filter.userType !== 'human'}"
              class="fas fa-user humanAccount"
          >
          </i>
        </label>
        <input
            @click="filter.userType === 'bot' ? filter.userType = '' : filter.userType = 'bot'"
            class="hiddenRadio"
            id="botAccount"
            type="radio">
        <label for="botAccount">
          <i
              v-bind:class="{grayAccount : filter.userType !== 'bot'}"
              class="fas fa-robot botAccount"
          >
          </i>
        </label>
      </div>

      <b-button type="submit" variant="primary" size="sm">Пошук</b-button>
      <b-button type="reset" variant="danger" size="sm">Скинути</b-button>
      <b-button variant="outline-primary" size="sm" @click="exportToCSV">Експорт в .csv</b-button>
    </b-form>
    <Loader v-if="loading"></Loader>
    <template v-else>
      <table class="totals">
        <thead>
          <th>Всього</th>
          <th>Помилка</th>
          <th>Заплановано</th>
          <th>Опубліковано</th>
          <th>Перевірка 1</th>
          <th>Перевірка 2</th>
        </thead>
        <tbody>
          <tr>
            <td>{{totals.total}}</td>
            <td>{{totals.error}}</td>
            <td>{{totals.planned}}</td>
            <td>{{totals.published}}</td>
            <td>{{totals.check1}}</td>
            <td>{{totals.check2}}</td>
          </tr>
        </tbody>
      </table>
      <StatsTable :statsData="statsData"></StatsTable>
    </template>
  </div>
</template>

<script>

import Loader from "@/components/Loader";
import StatsTable from "@/components/stats/StatsTable";
import api from "@/api";
export default {
  name: "Stats",
  created() {
    this.loadClientNames();
    this.resetFilters();
    this.reloadStatsData();
  },
  data() {
    return {
      filter: {},
      clients: [],
      totals: {},
      statsData : [],
      loading : false
    }
  },
  methods: {
    loadClientNames : async function () {
      await api.getClientNames()
          .then(response => {
            this.clients = response.data;
            this.clients.unshift("");
          });
    },
    reloadStatsData : async function () {
      this.loading = true;
      await api.getReviewStats(this.filter)
          .then(response => {
            this.statsData = response.data.reviewStatList;
            this.totals = response.data.totals;
          });
      this.loading = false;
    },
    applyFilters : async function(event) {
      event.preventDefault();
      await this.reloadStatsData();
    },
    resetFilters : function(event) {
      if (typeof event !== 'undefined')
        event.preventDefault();
      this.filter = {
        dateTo: null,
        dateFrom: null,
        statusFrom: null,
        statusTo: null,
        client: null,
        userType: null
      }
      let from = new Date();
      from.setDate(from.getDate() - 7);
      this.filter.dateFrom = from;
      let to = new Date();
      to.setDate(to.getDate() + 7);
      this.filter.dateTo = to;
      this.filter.statusFrom = 3;
      this.filter.statusTo = 8;
      this.filter.client = "";
      this.filter.userType = "";
    },
    exportToCSV : function() {
      const delimiter = ";";
      const header = ['id', 'Клієнт', "URL", "Дата", "Відгук", "Користувач", "Статус"];
      const csv = [
        header.join(delimiter),
        ...this.statsData.map( row =>
            row.id + delimiter +
            row.client + delimiter +
            row.url + delimiter +
            row.postDate + delimiter +
            row.fullReview.replace(/(\r\n|\n|\r)/gm, "") + delimiter +
            row.user + delimiter +
            this.statusDataFor(row.status).status
        )
      ].join('\r\n');
      this.download("reviews.csv", csv);
    },
    download: function(filename, text) {
      const element = document.createElement('a');

      element.setAttribute('href', 'data:text/csv;charset=windows-1251,' + this.encodeURL(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    encodeURL: function(input) {
      let object = {};
      let hasOwnProperty = object.hasOwnProperty;

      let INDEX_BY_CODE_POINT = {'152':24,'160':32,'164':36,'166':38,'167':39,'169':41,'171':43,'172':44,'173':45,'174':46,'176':48,'177':49,'181':53,'182':54,'183':55,'187':59,'1025':40,'1026':0,'1027':1,'1028':42,'1029':61,'1030':50,'1031':47,'1032':35,'1033':10,'1034':12,'1035':14,'1036':13,'1038':33,'1039':15,'1040':64,'1041':65,'1042':66,'1043':67,'1044':68,'1045':69,'1046':70,'1047':71,'1048':72,'1049':73,'1050':74,'1051':75,'1052':76,'1053':77,'1054':78,'1055':79,'1056':80,'1057':81,'1058':82,'1059':83,'1060':84,'1061':85,'1062':86,'1063':87,'1064':88,'1065':89,'1066':90,'1067':91,'1068':92,'1069':93,'1070':94,'1071':95,'1072':96,'1073':97,'1074':98,'1075':99,'1076':100,'1077':101,'1078':102,'1079':103,'1080':104,'1081':105,'1082':106,'1083':107,'1084':108,'1085':109,'1086':110,'1087':111,'1088':112,'1089':113,'1090':114,'1091':115,'1092':116,'1093':117,'1094':118,'1095':119,'1096':120,'1097':121,'1098':122,'1099':123,'1100':124,'1101':125,'1102':126,'1103':127,'1105':56,'1106':16,'1107':3,'1108':58,'1109':62,'1110':51,'1111':63,'1112':60,'1113':26,'1114':28,'1115':30,'1116':29,'1118':34,'1119':31,'1168':37,'1169':52,'8211':22,'8212':23,'8216':17,'8217':18,'8218':2,'8220':19,'8221':20,'8222':4,'8224':6,'8225':7,'8226':21,'8230':5,'8240':9,'8249':11,'8250':27,'8364':8,'8470':57,'8482':25};

      let length = input.length;
      let index = -1;
      let codePoint;
      let pointer;
      let result = '';
      while (++index < length) {
        codePoint = input.charCodeAt(index);
        // “If `code point` is in the range U+0000 to U+007F, return a byte whose
        // value is `code point`.”
        if (codePoint >= 0x00 && codePoint <= 0x7F) {
          result += '%' + (codePoint<16?'0':'') + codePoint.toString(16);
          continue;
        }
        // “Let `pointer` be the index pointer for `code point` in index
        // `single-byte`.”
        if (hasOwnProperty.call(INDEX_BY_CODE_POINT, codePoint)) {
          pointer = INDEX_BY_CODE_POINT[codePoint];
          // “Return a byte whose value is `pointer + 0x80`.”
          result += '%' + (codePoint<16?'0':'') + (pointer + 0x80).toString(16);
        }
      }
      return result.toUpperCase();
    },
    statusDataFor(status) {
      switch (status) {
        case -1:
          return {
            status: "Помилка",
            style: "background-color: #a50"
          }
        case 0:
          return {
            status: "Новий",
            style: "background-color: #aaa"
          }
        case 1:
        case 2:
          return {
            status: "Збережено",
            style: "background-color: #f60"
          }
        case 3:
        case 4:
        case 5:
          return {
            status: "Заплановано",
            style: "background-color: #cc0"
          }
        case 6:
          return {
            status: "Опубліковано",
            style: "background-color: #2a5"
          }
        case 7:
          return {
            status: "Перевірка 1",
            style: "background-color: #2c5"
          }
        case 8:
          return {
            status: "Перевірка 2",
            style: "background-color: #2f5"
          }
      }
    }
  },
  components: {StatsTable, Loader}
}
</script>

<style scoped>
  #stats {
    display: flex;
    flex-direction: column;
  }

  .filterForm {
    padding: 10px;
    color: #abadd0;
    font-size: 1.3em;
  }

  .selectType {
    display: flex;
    flex-direction: row;
    float: right;
    font-size: 1.2em;
  }

  .selectType label {
    padding-left: 10px;
    cursor: pointer;
  }

  .hiddenRadio {
    visibility: hidden;
    display: none;
  }

  .humanAccount {
    color: orange;
  }

  .botAccount {
    color: #00ff05;
  }

  .grayAccount {
    color: dimgrey!important;
  }

  .totals {
    padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 20px;
    background-color: darkslategray;
    color: #cceeff;
  }

  .totals thead {
    font-size: 1.1em;
  }

  .totals tbody {
    font-size: 1.8em;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
</style>