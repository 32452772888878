<template>
  <div class="review">
    <div
        class="status"
        :style="getStyle(review.status)"
        >
      {{statusNames[review.status] != null ? statusNames[review.status] : 'Помилка'}}
    </div>

    <b-form-datepicker v-model="review.postDate" size="sm" class="dateField" :disabled="review.status >= 6"
                       :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'long' }" locale="ua">
    </b-form-datepicker>
    <div class="user">
      <div class="refreshButton" v-if="review.status > 0 && review.status < 6" @click="$emit('change-user', review)">
        <i class="fas fa-sync-alt"></i>
      </div>
      <p @click="writeToBuffer(review.userLogin)" style="cursor: pointer; margin: 0; padding: 0;">
        {{review.userLogin == null ? "-" : review.userLogin}}
      </p>
    </div>
    <textarea class="reviewText" v-model="review.text" placeholder="Відгук..."></textarea>
    <div class="commentContainer">
      <b-form-input
          class="commentInput"
          type="text"
          v-model="review.comment"
          size="sm"
          placeholder="Коментар...">
      </b-form-input>
      <label
          class="telegramButton"
          :class="{grayAccount : !review.sendComment}"
          :for="'sendComment' + review.id">
        <i class="fab fa-telegram"></i>
      </label>
      <input
          class="hiddenRadio telegramButtonInput"
          :id="'sendComment' + review.id"
          type="checkbox"
          @click="review.sendComment = !review.sendComment">
    </div>


    <div class="buttons">
      <div class="selectGender">
        <input
            @click="review.gender = 'neutral'"
            class="hiddenRadio"
            :id="'neutralGender' + review.id"
            type="radio">
        <label :for="'neutralGender' + review.id">
          <i
              v-bind:class="{grayGender : review.gender !== 'neutral'}"
              class="fa fa-venus-mars neutralGender"
          >
          </i>
        </label>
        <input
            @click="review.gender = 'male'"
            class="hiddenRadio"
            :id="'maleGender' + review.id"
            type="radio">
        <label :for="'maleGender' + review.id">
          <i
              v-bind:class="{grayGender : review.gender !== 'male'}"
              class="fa fa-mars maleGender"
          >
          </i>
        </label>
        <input
            @click="review.gender = 'female'"
            class="hiddenRadio"
            :id="'femaleGender' + review.id"
            type="radio">
        <label :for="'femaleGender' + review.id">
          <i
              v-bind:class="{grayGender : review.gender !== 'female'}"
              class="fa fa-venus femaleGender"
          >
          </i>
        </label>
      </div>
      <div class="errorMessage">
        {{review.errorMessage}}
      </div>
      <div class="selectType">
        <input
            @click="review.type === 'human' ? review.type = null : review.type = 'human'"
            class="hiddenRadio"
            :id="'humanAccount' + review.id"
            type="checkbox">
        <label :for="'humanAccount' + review.id">
          <i
              v-bind:class="{grayAccount : review.type !== 'human'}"
              class="fas fa-user humanAccount"
          >
          </i>
        </label>
        <input
            @click="review.type === 'bot' ? review.type = null : review.type = 'bot'"
            class="hiddenRadio"
            :id="'botAccount' + review.id"
            type="radio">
        <label :for="'botAccount' + review.id">
          <i
              v-bind:class="{grayAccount : review.type !== 'bot'}"
              class="fas fa-robot botAccount"
          >
          </i>
        </label>
      </div>
      <img src="img/eng.png" :class="{grayImg : !review.english}" style="width: 32px; height: 32px; margin: 4px;" @click="review.english = !review.english">
    </div>


    <div class="manage">
      <b-button variant="danger" @click="deleteReview">Видалити</b-button>
      <b-button v-if="review.status === -1" variant="info" @click="rescheduleAfterError(review)">Відновити</b-button>
      <!--b-button variant="success">Зберегти</b-button -->
    </div>

  </div>
</template>

<script>

export default {
  props: ['review'],
  data() {
    return {
      statusNames: [
          'Новий',
          'Збережений',
          'Збережений',
          'Запланований',
          'Час: ',
          'Надіслано',
          'Опубліковано',
          'Перевірено №1',
          'Перевірено №2'
      ]
    }
  },
  methods: {
    getStyle(status) {
      switch (status) {
        case -1:
          return "background-color: #b90200";
        case 0:
          return "background-color: #aaa";
        case 1:
        case 2:
          return "background-color: #f60";
        case 3:
        case 4:
        case 5:
          return "background-color: #cc0";
        case 6:
        case 7:
        case 8:
          return "background-color: #2e5";
      }
    },
    deleteReview() {
      if (this.review.status === 0) {
          this.$emit('delete-review', this.review);
      } else {
        this.$confirm({
          message: 'Відгук буде видалено. Відмінити цю дію неможливо. Продовжити?',
          button: {
            yes: 'Так',
            no: 'Ні'
          },
          callback: confirm => {
            if (confirm) {
              this.$emit('delete-review', this.review);
            }
          }
        });
      }
    },
    rescheduleAfterError(review) {
      this.$emit('reschedule', review);
    },
    writeToBuffer(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showToast("Скопійовано!");
      });
    },
    showToast(message) {
      this.$toasted.info(message, {
        position: 'top-center',
        duration: 1200,
        keepOnHover: false,
        theme: 'bubble'
      });
    }
  },
  name: "Review"
}
</script>

<style scoped>
    .review {
      height: 530px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, .1);
      margin-left: 10px;
      margin-bottom: 10px;
      width: 32%;
    }

    .reviewText {
      resize: none;
      width: 94%;
      height: 48%;
      background-color: rgba(255, 255, 255, .6);
      margin: 0 3%;
      color: #2c3e50;
    }

    .reviewText:focus {
      outline: #13599d;
    }

    .dateField {
      width: 94%;
      margin: 5px auto;
    }

    .neutralGender {
      color: greenyellow;
      cursor: pointer;
    }

    .maleGender {
      color: blue;
      cursor: pointer;
    }

    .femaleGender {
      color: crimson;
      cursor: pointer;
    }

    .grayGender {
      color: dimgrey!important;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      width: 94%;
      margin: 0 auto;
      padding: 0;
      font-weight: 600;
      font-size: 1.4em;
    }

    .selectGender {
      display: flex;
      flex-direction: row;
      float: left;
    }

    .selectGender label {
      padding-right: 10px;
    }

    .selectType {
      display: flex;
      flex-direction: row;
      float: right;
    }

    .selectType label {
      padding-left: 10px;
    }

    .errorMessage {
      margin: 0 auto;
      color: #b90200;
      font-family: "Calibri Light";
      font-weight: 100;
      font-size: .7em;
      text-align: center;
      flex-grow: 1;
      vertical-align: top;
    }

    .hiddenRadio {
      visibility: hidden;
      display: none;
    }

    .humanAccount {
      color: orange;
    }

    .botAccount {
      color: #00ff05;
    }

    .grayAccount {
      color: dimgrey!important;
    }

    .grayImg {
      filter: grayscale(100%);
    }

    .status {
      text-align: center;
      font-family: 'Roboto Mono', monospace;
      font-size: 1.1em;
      font-weight: 500;
      margin-bottom: 7px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    }

    .commentContainer {
      width: 94%;
      margin: 0 auto;
      padding-bottom: 10px;
    }

    .commentInput {
      display: inline-block;
      width: 90%;
      vertical-align: top;
    }



    .telegramButton {
      font-size: 1.85em;
      line-height: 1;
      color: #009fd0;
      float: right;
      margin: 0;
    }

    .telegramButtonInput {
    }

    .manage {
      text-align: center;
    }

    .user {
      width: 94%;
      margin: 5px auto;
      color: #cceeff;
      font-weight: 600;
      font-family: Verdana;
    }

    .refreshButton {
      display: inline-block;
      float: right;
      color: #c95900;
      cursor: pointer;
    }

    .refreshButton:hover {
      color: #a93900!important;
    }

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
</style>