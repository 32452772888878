<template>
  <div id="reviewPack" class="d-flex flex-wrap flex-row justify-content-start align-items-start">
    <Loader v-if="loading"></Loader>
    <template v-else>
      <Options
          :reviewPack="reviewPack"
          ref="options"
          @savePack="$emit('savePack')"
          @deletePack="deletePack"
          @updatePack="$emit('updatePack')"
      >
      </Options>

      <Review
          @delete-review="$emit('deleteReview', review)"
          @change-user="$emit('changeUser', review)"
          @update-review="$emit('updateReview', review)"
          @reschedule="$emit('reschedule', review)"
          v-for="review in reviewPack.reviewList"
          :review="review"
          :key="review.id">
      </Review>

      <div id="addButton" @click="addReview">
        <i class="fa fa-plus"></i>
      </div>
    </template>
  </div>
</template>

<script>
import Options from "@/components/reviews2/Options";
import Review from "@/components/reviews2/Review";
import Loader from "@/components/Loader";
export default {
  props: ['reviewPack'],
  name: "ReviewPack",
  data() {
    return {
      mockId : 1000000,
      loading : false
    }
  },
  computed: {
    running() {
      return this.reviewPack.running;
    }
  },
  watch: {
    running() {
      if (this.running) {
        let startDate = new Date();
        startDate.setDate(startDate.getDate() + 1);
        let freq = this.$refs.options.getCurrentFrequency();
        let inCurrentDay = 0;
        this.reviewPack.reviewList.forEach(review => {
          if (review.postDate === null) {
            review.postDate = new Date(startDate);
            inCurrentDay++;
            if (inCurrentDay >= freq.count) {
              startDate.setDate(startDate.getDate() + freq.delay);
              inCurrentDay = 0;
            }
            if (review.status > 0) {
              this.$emit('updateReview', review);
            }
          }
        });
      } else {
        this.reviewPack.reviewList.forEach(review => {
          if (review.status < 6) {
            review.postDate = null;
            review.userLogin = null;
            if (review.status > 0) {
              this.$emit('updateReview', review);
            }
          }
        });
      }
    }
  },
  methods: {
    async deletePack(packId) {
      this.$confirm(
          {
            message: 'Підтвердіть видалення пакету відгуків?',
            button: {
              yes: 'Так',
              no: 'Ні'
            },
            callback: confirm => {
              if (confirm) {
                this.$emit('deletePack', packId);
              }
            }
          });
    },
    addReview() {
      let review = {
        id: this.mockId,
        text: "",
        gender: "neutral",
        userLogin: null,
        postDate: this.reviewPack.running ? this.getNextDate() : null,
        published: false,
        type: 'bot',
        errorMessage: null,
        status: 0,
        comment: "",
        sendComment: false,
        english: false
      };
      this.mockId++;
      this.reviewPack.reviewList.push(review);
    },
    getNextDate() {
      let list = this.reviewPack.reviewList;
      let freq = this.$refs.options.getCurrentFrequency();
      if (list.length === 0) {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        return date;
      }
      let date = new Date(list[list.length - 1].postDate);
      if (date === null)
        return null;
      if (freq.count === 1) {
        date.setDate(date.getDate() + freq.delay);
      } else {
        list.forEach(r => console.log(r.postDate));
        let inLastDate = list.filter(
            r => {
              console.log(r.postDate + " - " + date);
              let filtered = (r.postDate.getDate() === date.getDate()
                  && r.postDate.getMonth() === date.getMonth());
              console.log(filtered);
              return filtered;
            }
        );
        if (inLastDate.length >= freq.count) {
          date.setDate(date.getDate() + 1);
        }
      }
      return date;
    }
  },
  components: {Loader, Review, Options}
}
</script>

<style scoped>
  #reviewPack {
    width: 80%;
    overflow-y: auto;
  }

  #addButton {
    font-size: 4.5em;
    line-height: 1.35;
    background-color: #2c6e50;
    color: white;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    text-align: center;
    margin-left: 50px;
    margin-top: 120px;
    cursor: pointer;
  }

  #addButton:hover {
    background-color: #3c7e60;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
</style>