<template>
  <div id="header">
    <b-navbar toggleable="sm" variant="dark" type="dark">
      <b-navbar-brand>feedback-bot</b-navbar-brand>
      <b-navbar-toggle target="navbar-toggle-collapse">
        <template #default="{ expanded }">
          <i v-if="expanded" class="fas fa-chevron-circle-up toggleIcon"></i>
          <i v-else class="fas fa-chevron-circle-down toggleIcon"></i>
        </template>
      </b-navbar-toggle>
      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item><router-link to="/users">Користувачі</router-link></b-nav-item>
          <b-nav-item><router-link to="/reviews2">Відгуки</router-link></b-nav-item>
          <b-nav-item><router-link to="/tasks">Завдання</router-link></b-nav-item>
          <b-nav-item><router-link to="/calendar">Календар</router-link> </b-nav-item>
          <b-nav-item><router-link to="/stats">Статистика</router-link></b-nav-item>
          <b-nav-item><router-link to="/deleted">Видалені відгуки</router-link></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
  .navbar-toggler:hover {
    border: none!important;
    outline: none!important;
  }

  .toggleIcon {
    font-size: 7vw;
    color: #cceeff;
  }
</style>