<template>
  <div id="addNewPack">
    <div class="bottomContainer">
      <div id="info">
        Зараз доступно {{usersInfo.usersCount}} аккаунтів.<br>
        {{usersInfo.maleCount}} чоловіків / {{usersInfo.femaleCount}} жінок<br>
      </div>
      <div id="options">
        <label for="client">Клієнт:</label>
        <input id="client" v-model="client" class="clientField">
        <br>
        <div style="display: inline-block; padding-top: 3px">Частота:</div>
        <div style="display: inline-block; margin-right: 10px;">
          <vue-slider
              class="frequencySlider"
              v-model="frequency"
              :min="0"
              :max="4"
              :interval="1"
              :tooltip="'none'"></vue-slider>
          <label style="margin-left: 10px">{{frequencyList[frequency]}}</label>
        </div>
        <div style="display: inline-block; padding-top: 3px">Посилання для відгуку:</div><br>
        <input class="urlField" type="text" v-model="reviewUrl" placeholder="http://google.com/...">
      </div>
      <div id="savePack">
        <i class="fa fa-save" @click="saveReviewPack"></i>
      </div>
    </div>
    <div class="reviewsContainer">
      <div class="review" v-for="review in reviews" :key="review.id" >
        <div style="text-align: right">
          <i
              class="fa fa-remove"
              @click="removeReview(review.id)"
              style="cursor: pointer; color: red; padding-top: 5px; padding-right: 5px">

          </i>
        </div>
        <textarea class="reviewText" v-model="review.text"></textarea>
        <div class="selectGender">
          <input
              @click="changeGender(review,'neutral')"
              class="hiddenRadio"
              :id="'neutralGender' + review.id"
              type="radio">
          <label :for="'neutralGender' + review.id">
            <i
                v-bind:class="{grayGender : review.gender !== 'neutral'}"
                class="fa fa-venus-mars neutralGender"
            >
            </i>
          </label>
          <input
              @click="changeGender(review,'male')"
              class="hiddenRadio"
              :id="'maleGender' + review.id"
              type="radio">
          <label :for="'maleGender' + review.id">
            <i
                v-bind:class="{grayGender : review.gender !== 'male'}"
                class="fa fa-mars maleGender"
            >
            </i>
          </label>
          <input
              @click="changeGender(review,'female')"
              class="hiddenRadio"
              :id="'femaleGender' + review.id"
              type="radio">
          <label :for="'femaleGender' + review.id">
            <i
                v-bind:class="{grayGender : review.gender !== 'female'}"
                class="fa fa-venus femaleGender"
            >
            </i>
          </label>
        </div>
      </div>
      <div class="review addReview" @click="addReview">
        <i class="fa fa-plus"></i>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import api from "@/api";

export default {
  name: "AddNewPack",
  data() {
    return {
      reviewUrl: "",
      reviewCounter: 0,
      client: "",
      frequencyList: ["1 відгук/3 дні", "1 відгук/2 дні", "1 відгук/день", "2 відгуки/день", "3 відгуки/день"],
      frequency: 0,
      reviews: [],
      usersInfo: {}
    }
  },
  created() {
    this.reloadUsersInfo();
  },
  methods: {
    saveReviewPack() {
      let reviewPack = {
        client: this.client,
        totalReviews: this.reviews.length,
        frequency: this.frequency,
        url: this.reviewUrl,
        reviewList: this.reviews
      };

      this.$emit('savePack', reviewPack);
    },
    reloadUsersInfo() {
      api.getUsersInfo().then(
          response => {
            this.usersInfo = response.data;
          }
      );
    },
    addReview() {
      if (this.reviews.length + 1 > this.usersInfo.usersCount) {
        this.$toasted.info(
            "К-ть відгуків не може перевищувати к-ть користувачів!",
            {
              position: 'top-center',
              duration: 3500,
              keepOnHover: true,
              theme: 'bubble'
            }
        );
        return ;
      }
      this.reviewCounter++;
      this.reviews.push({
        id: this.reviewCounter,
        text: "",
        gender: "neutral"
      });
    },
    removeReview(id) {
      this.reviews = this.reviews.filter(r => r.id !== id);
    },
    changeGender(review, gender) {
      if (gender === 'male') {
        let maleCount = this.reviews.filter(r => r.gender === 'male').length;
        if (maleCount + 1 > this.usersInfo.maleCount) {
          this.$toasted.info(
              "К-ть чоловічих відгуків не може перевищувати к-ть чоловіків!",
              {
                position: 'top-center',
                duration: 3500,
                keepOnHover: true,
                theme: 'bubble'
              }
          );
          return ;
        }
      } else if (gender === 'female') {
        let femaleCount = this.reviews.filter(r => r.gender === 'female').length;
        if (femaleCount + 1 > this.usersInfo.femaleCount) {
          this.$toasted.info(
              "К-ть жіночих відгуків не може перевищувати к-ть жінок!",
              {
                position: 'top-center',
                duration: 3500,
                keepOnHover: true,
                theme: 'bubble'
              }
          );
          return ;
        }
      }
      review.gender = gender;
    }
  },
  components: {
    VueSlider
  }
}
</script>

<style scoped>
  @media screen and (max-height: 750px){
    .reviewsContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap!important;
      overflow-y: hidden!important;
      overflow-x: auto!important;
      max-height: 300px!important;
    }
  }

  #addNewPack {
    color: burlywood;
    display: inline-block;
    vertical-align: top;
    width: 79%;
    margin-left: 30px;
    overflow-y: auto;
    text-align: center;
    height: 100%;
    padding: 5px;
  }

  .bottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 15px;
    width: inherit;
  }

  .reviewsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    padding: 15px;
    border-radius: 15px;
    background-color: #2c6e50;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 600px;
  }

  #info {
    padding: 15px;
    border-radius: 15px;
    background-color: white;
    align-self: stretch;
    flex-grow: 1;
    background-color: #2c6e50;
    max-width: 400px;
  }

  #options {
    text-align: left;
    padding: 15px;
    border-radius: 15px;
    background-color: white;
    align-self: stretch;
    flex-grow: 1;
    background-color: #2c6e50;
    font-size: 0.7em;
    max-width: 375px;
  }

  #options select {
    margin-left: 10px;
    width: 150px;
  }

  #options button {
    margin-left: 10px;
    background-color: springgreen;
    padding: 0;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: none;
  }

  .frequencySlider {
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 150px!important;
  }

  .review {
    min-width: 24%;
    flex-basis: 24%;
    width: 24%;
    height: 260px;
    border-radius: 20px;
    background-color: salmon;
    margin-right: 1%;
    margin-bottom: 10px;
  }

  .addReview {
    background-color: springgreen!important;
    color: white;
    text-shadow: #2c3e50 3px 3px 3px;
    font-size: 3.6em;
    cursor: pointer;
    border: 2px solid transparent;
    vertical-align: middle;
    line-height: 3.6em;
  }

  .addReview:hover {
    border: 2px solid crimson;
  }

  .reviewText:focus {
    outline: red;
  }

  .reviewText {
    resize: none;
    margin: 2%;
    width: 96%;
    height: 180px;
    border: none;
    background-color: #faa;
    font-size: 1.1vw;
    text-align: left;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
  }

  .neutralGender {
    color: greenyellow;
    font-weight: 600;
    font-size: 1.2em;
    cursor: pointer;
  }

  .maleGender {
    color: blue;
    font-weight: 600;
    font-size: 1.2em;
    cursor: pointer;
  }

  .femaleGender {
    color: crimson;
    font-weight: 600;
    font-size: 1.2em;
    cursor: pointer;
  }

  .grayGender {
    color: dimgrey!important;
  }

  .selectGender {
  }

  .hiddenRadio {
    visibility: hidden;
  }

  #savePack {
    font-size: 5.3em;
    font-weight: 600;
    cursor: pointer;
    background-color: green;
    color: #cceeff;
    border-radius: 20px;
    align-self: stretch;
    flex-grow: 1;
    max-width: 150px;
  }

  .urlField {
    width: 100%;
    border-radius: 10px;
  }

  .clientField {
    margin-left: 15px;
  }
</style>