<template>
  <div id="users">
    <vue-confirm-dialog></vue-confirm-dialog>
    <Loader v-if="loading"></Loader>
    <template v-else>
      <UserForm @save-pack="savePack" @create-user="createUser" ref="userForm" :msg="formNotify"></UserForm>
      <UsersTable ref="userTable" :msg="formNotify"></UsersTable>
    </template>
  </div>
</template>

<script>
import UsersTable from "@/components/users/UsersTable";
import UserForm from "@/components/users/UserForm";
import Loader from "@/components/Loader";
import api from "@/api";
export default {
  name: "Users",
  data() {
    return {
      loading : false,
      formNotify : 0
    }
  },
  methods: {
    async createUser(newUserData) {
      this.loading = true;
      await api.addUser(newUserData)
        .then(response => {
          if (response.status === 200) {
            this.formNotify++;
          }
        });
      this.loading = false;
    },
    async savePack(fileData) {
      this.loading = true;
      await api.addUserPack(fileData)
        .then(response => {
          if (response.status === 200) {
            this.formNotify++;
          }
        })
      this.loading = false;
    }
  },
  components: {Loader, UserForm, UsersTable}
}
</script>

<style scoped>
  #users {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 100%;
    width: 100vw;
  }
</style>