<template>
  <div id="reviewPack">
    <vue-confirm-dialog></vue-confirm-dialog>
    <div class="bottomContainer">
      <div id="options">
        <label for="client">Клієнт:</label>
        <input id="client" v-model="reviewPack.client" class="clientField" readonly>
        <br>
        <div style="display: inline-block; padding-top: 3px">Частота:</div>
        <div style="display: inline-block; margin-right: 10px;">
          <vue-slider
              class="frequencySlider"
              v-model="reviewPack.frequency"
              disabled=true
              :min="0"
              :max="4"
              :interval="1"
              :tooltip="'none'"></vue-slider>
          <label style="margin-left: 10px">{{frequencyList[reviewPack.frequency]}}</label>
        </div>
        <div style="display: inline-block; padding-top: 3px">Посилання для відгуку:</div><br>
        <input class="urlField" type="text" v-model="reviewPack.url" readonly>
      </div>
      <div id="deletePack">
        <i class="fa fa-remove" @click="deletePack"></i>
      </div>
      <!-- div id="savePack">
        <i class="fa fa-save" @click="saveReviewPack"></i>
      </div-->
    </div>
    <div class="reviewsContainer">
      <div class="review" v-for="review in reviewPack.reviewList" :key="review.id"
        :class="{completed : review.published}">

        <i class="fab fa-telegram telegramButton" @click="sendTelegramTest(review.id)"></i>

        <div style="float : left; color: #2c3e50; padding: 3px; text-align: left">
          {{review.userLogin}}<br>{{review.postDate}}
        </div>
        <textarea class="reviewText" v-model="review.text" readonly></textarea>
        <div class="selectGender">
          <input
              @click="changeGender(review,'neutral')"
              class="hiddenRadio"
              :id="'neutralGender' + review.id"
              type="radio">
          <label :for="'neutralGender' + review.id">
            <i
                v-bind:class="{grayGender : review.gender !== 'neutral'}"
                class="fa fa-venus-mars neutralGender"
            >
            </i>
          </label>
          <input
              class="hiddenRadio"
              :id="'maleGender' + review.id"
              type="radio">
          <label :for="'maleGender' + review.id">
            <i
                v-bind:class="{grayGender : review.gender !== 'male'}"
                class="fa fa-mars maleGender"
            >
            </i>
          </label>
          <input
              class="hiddenRadio"
              :id="'femaleGender' + review.id"
              type="radio">
          <label :for="'femaleGender' + review.id">
            <i
                v-bind:class="{grayGender : review.gender !== 'female'}"
                class="fa fa-venus femaleGender"
            >
            </i>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import api from "@/api";

export default {
  name: "ReviewPack",
  props: ['reviewPack'],
  data() {
    return {
      frequencyList: ["1 відгук/3 дні", "1 відгук/2 дні", "1 відгук/день", "2 відгуки/день", "3 відгуки/день"]
    }
  },
  methods: {
    sendTelegramTest(id) {
      api.testPlanMessage(id).then(
          this.$confirm(
              {
                message: 'Тестовий відгук відправлено!',
                button: {
                  yes: 'OK'
                }
              }
          )
      );

    },
    deletePack() {
      this.$confirm(
          {
            message: 'Підтвердіть видалення пакету відгуків?',
            button: {
              yes: 'Так',
              no: 'Ні'
            },
            callback: confirm => {
              if (confirm) {
                this.$emit('deletePack', this.reviewPack.id);
              }
            }
          });
    }
  },
  components: {
    VueSlider
  }
}
</script>

<style scoped>
@media screen and (max-height: 750px){
  .reviewsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap!important;
    overflow-y: hidden!important;
    overflow-x: auto!important;
    max-height: 300px!important;
  }
}

#reviewPack {
  color: burlywood;
  display: inline-block;
  vertical-align: top;
  width: 79%;
  margin-left: 30px;
  overflow-y: auto;
  text-align: center;
  height: 100%;
  padding: 5px;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 15px;
  width: inherit;
}

.reviewsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  padding: 15px;
  border-radius: 15px;
  background-color: #2c6e50;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}

#info {
  padding: 15px;
  border-radius: 15px;
  background-color: white;
  align-self: stretch;
  flex-grow: 1;
  background-color: #2c6e50;
  max-width: 400px;
}

#options {
  text-align: left;
  padding: 15px;
  border-radius: 15px;
  background-color: white;
  align-self: stretch;
  flex-grow: 1;
  background-color: #2c6e50;
  font-size: 0.7em;
  max-width: 375px;
}

#options select {
  margin-left: 10px;
  width: 150px;
}

#options button {
  margin-left: 10px;
  background-color: springgreen;
  padding: 0;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: none;
}

.frequencySlider {
  margin-top: 10px;
  margin-left: 10px;
  display: inline-block;
  width: 150px!important;
}

.review {
  min-width: 24%;
  flex-basis: 24%;
  width: 24%;
  height: 260px;
  border-radius: 20px;
  background-color: salmon;
  margin-right: 1%;
  margin-bottom: 10px;
}

.addReview {
  background-color: springgreen!important;
  color: white;
  text-shadow: #2c3e50 3px 3px 3px;
  font-size: 3.6em;
  cursor: pointer;
  border: 2px solid transparent;
  vertical-align: middle;
  line-height: 3.6em;
}

.addReview:hover {
  border: 2px solid crimson;
}

.reviewText:focus {
  outline: red;
}

.reviewText {
  resize: none;
  margin: 2%;
  width: 96%;
  height: 160px;
  border: none;
  background-color: #faa;
  font-size: 1.1vw;
  text-align: left;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
}

.neutralGender {
  color: greenyellow;
  font-weight: 600;
  font-size: 1.2em;
  cursor: pointer;
}

.maleGender {
  color: blue;
  font-weight: 600;
  font-size: 1.2em;
  cursor: pointer;
}

.femaleGender {
  color: crimson;
  font-weight: 600;
  font-size: 1.2em;
  cursor: pointer;
}

.grayGender {
  color: dimgrey!important;
}

.selectGender {
}

.hiddenRadio {
  visibility: hidden;
}

#savePack {
  font-size: 5.3em;
  font-weight: 600;
  cursor: pointer;
  background-color: green;
  color: #cceeff;
  border-radius: 20px;
  align-self: stretch;
  flex-grow: 1;
  max-width: 150px;
  margin-left: 10px;
}

#deletePack {
  font-size: 5.3em;
  font-weight: 600;
  cursor: pointer;
  background-color: crimson;
  color: #cceeff;
  border-radius: 20px;
  align-self: stretch;
  flex-grow: 1;
  max-width: 150px;
  margin-left: 10px;
}

.urlField {
  width: 100%;
  border-radius: 10px;
}

.clientField {
  margin-left: 15px;
}

.completed {
  background-color: #82c175!important;
}

.telegramButton {
  cursor: pointer;
  color: #13599d;
  float: right;
  margin-right: 15px;
  margin-top: 10px;
  font-size: 36px;
  width: 40px;
  height: 40px;
}

.telegramButton:hover {
  color: #13796d;
}
</style>