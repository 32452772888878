import Vue from 'vue'
import VueRouter from 'vue-router'
import Stats from "@/views/Stats";
import Users from "@/views/Users";
import Reviews from "@/views/Reviews";
import Reviews2 from "@/views/Reviews2";
import TodayTasks from "@/views/TodayTasks";
import LoadCalendar from "@/views/LoadCalendar";
import DeletedReviews from "@/views/DeletedReviews.vue";

Vue.use(VueRouter)


let router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/users',
            name: 'Users',
            component: Users,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/stats',
            name: 'Stats',
            component: Stats,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/reviews',
            name: 'Reviews',
            component: Reviews,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/reviews2',
            name: 'Reviews2',
            component: Reviews2,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tasks',
            name: 'TodayTasks',
            component: TodayTasks
        },
        {
            path: '/calendar',
            name: 'LoadCalendar',
            component: LoadCalendar
        },
        {
            path: '/deleted',
            name: 'DeletedReviews',
            component: DeletedReviews
        }
    ]
});

export default router;