<template>
  <div id="statsTableContainer">
    <table class="table table-dark table-striped">
      <thead>
      <tr>
        <th>#</th>
        <th>Клієнт</th>
        <th>Бот акаунт</th>
        <th>Відгук</th>
        <th>Дата публікації</th>
        <th>Дата видалення</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(stats, index) in statsData" :key="stats.id">
        <td>{{index + 1}}</td>
        <td>{{stats.clientName}}</td>
        <td v-if="stats.actualBot">{{stats.actualBot}}</td>
        <td v-else>{{stats.botLogin}}</td>
        <td>{{stats.text}}</td>
        <td>{{stats.postDate}}</td>
        <td>{{stats.deletedDate}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props : ["statsData"],
  name: "DeletedReviewsTable",
  methods: {
    formatDate: function (date) {
      if (date == null || typeof date == 'undefined' ) {
        return "";
      } else {
        let hours = new Date(date).getHours();
        let minutes = new Date(date).getMinutes();
        let result = hours + ":" + (minutes > 9 ? minutes : "0" + minutes);
        return result;
      }
    }
  }
}
</script>

<style scoped>
#statsTableContainer {
  display: flex;
  flex-direction: row;
  vertical-align: top;
  font-size: 1.0vw;
  color: white;
  height: 72vh;
  overflow-y: auto;
  padding: 10px;
  text-align: left;
}

.done {
  font-size: 1.5vw;
  color: #00ff05;
}

.reviewText {
  font-family: 'Roboto Mono', monospace;
}

.status {
  border-radius: 10px;
  text-align: center;
  padding: 3px;
  font-size: 1.1em;
  color: #2c3e50;
}
</style>