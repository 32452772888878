import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from "@/router";
import Vuelidate from 'vuelidate'
import VueConfirmDialog from 'vue-confirm-dialog'
import ToastedPlugin from "vue-toasted";


Vue.use(Vuelidate)
Vue.use(ToastedPlugin)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
