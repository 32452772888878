import axios from 'axios'

//const SERVER_URL = 'https://api.feedbackbot.work/planner'
const SERVER_URL = 'http://localhost:8080/planner'

const instance = axios.create({
    baseURL: SERVER_URL,
    timeout: 30000
});

export default {
    async execute(method, resource, data) {
        return instance({
            method: method,
            url: resource,
            data
        })
    },
    getUsers() {
        return this.execute('GET', 'users');
    },
    addUser(user) {
        return this.execute('POST', 'users', user);
    },
    addUserPack(userPack) {
        return this.execute('POST', 'users/pack', userPack)
    },
    deleteUser(id) {
        return this.execute('DELETE', 'users/' + id);
    },
    changeUserStatus(id)
    {
        return this.execute('PATCH', 'users/status/' + id);
    },
    getUsersInfo() {
        return this.execute('GET', 'users/info')
    },
    getPacks() {
        return this.execute('GET', 'reviews');
    },
    getPackInfo(id) {
        return this.execute('GET', 'reviews/info/' + id);
    },
    getTags() {
        return this.execute('GET', 'reviews/tags')
    },
    getStats(year, month) {
        return this.execute('GET', 'stats/' + year + "/" + month);
    },
    sendTelegramTest(userId) {
        return this.execute('POST', 'telegram/' + userId);
    },
    saveReviewPack(reviewPack) {
        return this.execute('POST', 'reviews', reviewPack)
    },
    updateReviewPack(reviewPack) {
        return this.execute('POST', 'update-pack', reviewPack)
    },

    getReviewPack(id) {
        return this.execute('GET', 'reviews/' + id)
    },
    deleteReviewPack(id) {
        return this.execute('DELETE', 'reviews/' + id)
    },
    deleteReview(id) {
        return this.execute('DELETE', 'reviews/review/' + id)
    },
    testPlanMessage(id) {
        return this.execute('GET', 'telegram/plan/' + id)
    },
    getUsersNames() {
        return this.execute('GET', 'stats/users')
    },
    getClientNames() {
        return this.execute('GET', 'stats/clients')
    },
    getReviewStats(reviewFilters) {
        return this.execute('POST', 'stats', reviewFilters)
    },
    getReviewList(listType) {
        return this.execute('GET', 'reviewsList/' + listType)
    },
    getTodayTasks(type) {
        return this.execute('GET', 'tasks/' + type)
    },
    changeTaskStatus(id, expected, actualBot) {
        return this.execute('PATCH', 'tasks/status/' + id + '/expected/' + expected + '/actualBot/' + actualBot)
    },
    setTaskDeleted(id) {
        return this.execute('PATCH', 'tasks/deleted/' + id)
    },
    rescheduleTask(id) {
        return this.execute('PATCH', 'tasks/reschedule/' + id)
    },
    changeUserForReview(review) {
        return this.execute('POST', 'change-user', review)
    },
    updateReview(review) {
        return this.execute('POST', 'update-review', review)
    },
    rescheduleAfterError(review) {
        return this.execute('POST', 'reviews/reschedule', review)
    },
    getDeletedReviews() {
        return this.execute('GET', 'stats/deleted')
    }
}