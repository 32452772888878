<template>
  <div id="loadCalendar">
    <Calendar></Calendar>
  </div>
</template>

<script>
import Calendar from "@/components/common/Calendar";
export default {
name: "LoadCalendar",
  components: {Calendar}
}
</script>

<style scoped>
  #loadCalendar {

  }
</style>